import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

// components
import MainLayout from 'components/Layout/Main';
import CreatorCard from './components/CreatorCard';
import Search from 'components/GlobalUi/Search/Search';

// assets
import { ReactComponent as DualRing } from 'assets/svg/Dual-Ring-red.svg';
import emptyUser from 'assets/images/Empty-user.png';

// styles
import styles from './index.module.scss';

// redux
import {
  getCreatorsList,
  removeCreator,
  selectContentCreator,
} from 'reducers/contentCreatorReducer';

// utils
import { SHORTFLIX_ROUTE } from 'utils/constants';
import RemoveConfirmationModal from './components/RemoveConfirmationModal';
import Pagination from './components/Pagination';

const Index = () => {
  const location = useLocation();
  const p = new URLSearchParams(location.search);
  const pageParams = p.getAll('page').toString();
  const navigate = useNavigate(); // Get the history object for updating the URL
  const dispatch = useDispatch();

  const { creatorListData, isLoading } = useSelector(selectContentCreator);

  const [currentPage, setCurrentPage] = useState(1);
  const [isRemoveModalVisible, setRemoveModalVisible] = useState(false);
  const [creatorId, setCreatorId] = useState(0);
  const [fName, setFullName] = useState('');

  const [searchTerm, setSearchTerm] = useState('');

  const toPage = (pageNumber) => {
    // Update the URL with the new page number
    navigate(`${SHORTFLIX_ROUTE.ContentCreatorsList}?page=${pageNumber}`);
    setCurrentPage(pageNumber);
  };
  const params = {
    page: pageParams,
    status: 'approved',
  };

  useEffect(() => {
    dispatch(getCreatorsList(params));
  }, [pageParams]);

  const filteredCreatorData = useMemo(() => {
    return creatorListData?.data?.filter((item) => {
      return item.attributes.full_name.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [creatorListData, searchTerm]);

  const handleRemoveModal = (id, fullName) => {
    setRemoveModalVisible(true);
    setCreatorId(id);
    setFullName(fullName);
  };

  const handleRemoveCreator = async () => {
    await dispatch(removeCreator(creatorId));
    await toast.success('Successfully deleted creator.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      theme: 'light',
    });
    await setRemoveModalVisible(false);
    setTimeout(() => dispatch(getCreatorsList(params)), 1000);
  };

  const onSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  return (
    <MainLayout title='Content Creator List'>
      <Container style={{ maxWidth: 1700 }}>
        <div>
          {isLoading ? (
            <div className={styles?.loaderWrapper}>
              <DualRing className={styles?.loader} />
            </div>
          ) : (
            <div>
              <div className={styles?.creatorSearch}>
                <Search onChange={onSearch} />
              </div>
              <Row className={styles?.row}>
                {filteredCreatorData?.map((creator, id) => {
                  return (
                    <Col xs={12} md={6} lg={6} xl={5} xxl={4} key={id}>
                      <CreatorCard
                        id={creator?.id}
                        image={
                          creator.attributes?.profile_pic
                            ? creator.attributes?.profile_pic
                            : emptyUser
                        }
                        fullName={creator.attributes?.full_name}
                        email={creator.attributes?.email}
                        mobileNumber={creator.attributes?.mobile_number}
                        status={creator.attributes.status}
                        onClickRemoveCreator={() =>
                          handleRemoveModal(creator?.id, creator.attributes?.full_name)
                        }
                      />
                    </Col>
                  );
                })}
                <Pagination
                  backToPage={() => toPage(creatorListData?.meta?.pagination?.current_page - 1)}
                  goToPage={() => toPage(creatorListData?.meta?.pagination?.current_page + 1)}
                  currentPage={currentPage}
                  totalPages={creatorListData?.meta?.pagination?.total_pages}
                />
              </Row>
            </div>
          )}
        </div>
      </Container>
      <RemoveConfirmationModal
        title='CONFIRM REMOVE CREATOR'
        show={isRemoveModalVisible}
        onRemove={handleRemoveCreator}
        onHide={() => setRemoveModalVisible(false)}
        fName={fName}
      />
      <ToastContainer />
    </MainLayout>
  );
};

export default Index;
