import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

// styles
import styles from '../index.module.scss';

// components
import { Label } from 'components/GlobalUi/Typography';
import Input from 'components/GlobalUi/Input/Input';
import Button from 'components/GlobalUi/Button/Button';

// redux
import { addTerms, removeTerms, getTermsPolicy, updateTerms } from 'reducers/thunks/termsThunk';

// validation
import termsValidationSchema from '../validationSchema';

const Terms = ({ preloadedData }) => {
  const dispatch = useDispatch();

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(termsValidationSchema),
  });

  useEffect(() => {
    setValue('title', preloadedData[0]?.attributes?.title);
    setValue('description', preloadedData[0]?.attributes?.description);
  }, [preloadedData, setValue]);

  const onSubmitTerms = async () => {
    const params = {
      title: watch('title'),
      description: watch('description'),
      type: 'terms',
      id: preloadedData[0]?.id,
    };
    try {
      if (!params.id) {
        dispatch(addTerms(params));
      } else {
        dispatch(updateTerms(params));
      }
    } catch {
      toast.error('Something went wrong', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light',
      });
    } finally {
      dispatch(getTermsPolicy());
    }
    /* toast.error('Term update successfully.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      theme: 'light',
    }); */
  };

  const onRemoveTerms = async () => {
    await dispatch(removeTerms(preloadedData[0]?.id));
    toast.success('Term delete successfully.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      theme: 'light',
    });
    dispatch(getTermsPolicy());
  };

  return (
    <div>
      <div className={styles?.terms__top}>
        <Label black01 medium>
          Terms and Conditions
        </Label>
      </div>
      <form className={styles?.formWrapper} onSubmit={handleSubmit(onSubmitTerms)}>
        <div className={styles?.formContainer}>
          <Label gray2 medium>
            Title
          </Label>
          <div className={styles?.input}>
            <Controller
              control={control}
              name='title'
              render={({ field }) => (
                <Input
                  type='text'
                  name='title'
                  {...field}
                  placeholder='Enter Title'
                  noBorder
                  bgGray
                  roundedLg
                  paddingLgX
                  black01
                />
              )}
            />

            {errors.title && (
              <Label sm primary>
                {errors.title.message}
              </Label>
            )}
          </div>
        </div>
        <div className={styles?.formContainer}>
          <Label gray2 medium>
            Description
          </Label>
          <div>
            <Controller
              control={control}
              name='description'
              render={({ field }) => (
                <textarea
                  name='description'
                  id='answer'
                  {...field}
                  cols='30'
                  placeholder='Enter Description'
                />
              )}
            />
            {errors.description && (
              <Label sm primary>
                {errors.description.message}
              </Label>
            )}
          </div>
        </div>
        {preloadedData.length > 0 ? (
          <div className={styles?.btnWrapper}>
            <Button approve height50 type='submit'>
              Update
            </Button>
            <Button decline height50 type='submit' onClick={onRemoveTerms}>
              Delete
            </Button>
          </div>
        ) : (
          <div className={styles?.btnWrapper}>
            <Button approve height50 type='submit'>
              Save
            </Button>
          </div>
        )}
      </form>
      <ToastContainer />
    </div>
  );
};

export default Terms;
