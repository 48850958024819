import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Button = styled.button.withConfig({
  shouldForwardProp: (prop) =>
    !['fullWidth', 'primary', 'grey01', 'disabled', 'approve', 'decline'].includes(prop),
})`
  min-width: 60px;
  height: 59px;
  border: none;
  border-radius: 4px;

  &:hover {
    -webkit-box-shadow: 3px 3px 10px 6px #dddddd;
    -moz-box-shadow: 3px 3px 10px 6px #dddddd;
    box-shadow: 3px 3px 10px 6px #dddddd;
  }

  ${(props) =>
    props.height50 &&
    css`
      height: 50px;
    `};

  ${(props) =>
    props.fullWidth &&
    css`
      min-width: 100%;
    `};

  ${(props) =>
    props.primary &&
    css`
      background-color: #ca2d2d;
    `};

  ${(props) =>
    props.grey01 &&
    css`
      background-color: #eeeeee;
    `};

  ${(props) =>
    props.disabled &&
    css`
      background: #999;
      color: #555;
      cursor: not-allowed !important;
    `};

  ${(props) =>
    props.approve &&
    css`
      min-width: 145px;

      color: #02cb60;
      background: #edf9f4 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
    `};

  ${(props) =>
    props.decline &&
    css`
      min-width: 145px;

      color: #d1002a;
      background: #ffdfe6 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
    `};
`;

Button.propTypes = {
  fullWidth: PropTypes.bool,
  primary: PropTypes.bool,
  grey01: PropTypes.bool,
  disabled: PropTypes.bool,
  approve: PropTypes.bool,
  decline: PropTypes.bool,
};

export default Button;
