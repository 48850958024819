/* eslint-disable camelcase */

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// components
import MainLayout from 'components/Layout/Main';
import Search from 'components/GlobalUi/Search/Search';
import Select from 'components/GlobalUi/Input/Select';
import MainTable from 'components/GlobalUi/Table/table';
import { Label } from 'components/GlobalUi/Typography';
import { Badge } from 'components/GlobalUi/Badge/Badge';

// styles
import styles from './index.module.scss';

// assets
import { ReactComponent as EllipsisVertical } from 'assets/svg/Ellipsis-vertical.svg';

// redux
import { getPayoutList } from 'reducers/thunks/payoutListThunk';
import { selectPayout } from 'reducers/payoutReducer';

// utils
import { FILTER_STATUS, PAGES, SHORTFLIX_ROUTE } from 'utils/constants';
import FormattedNumber from 'helpers/formattedNumber';
import { Container } from 'react-bootstrap';

const Index = () => {
  const dispatch = useDispatch();
  const { payoutData, isLoading } = useSelector(selectPayout);

  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('');

  useEffect(() => {
    const params = {
      perPage: PAGES.PerPage,
    };
    dispatch(getPayoutList(params));
  }, []);

  const onSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredPayoutData = React.useMemo(() => {
    return payoutData.filter((item) => {
      return item.attributes.account_name.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [payoutData, searchTerm]);

  console.log('filteredPayoutData', filteredPayoutData?.attributes?.read_at);

  const onFilterStatus = (value) => {
    const params = {
      perPage: PAGES.PerPage,
      status: value,
    };
    dispatch(getPayoutList(params));
    setFilterStatus(value);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'BANK',
        accessor: 'payment_channel', // accessor is the "key" in the data
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Label md gray2 capitalize>
              {row.original.attributes?.payment_channel}
            </Label>
          </div>
        ),
      },
      {
        Header: 'CONTENT CREATOR NAME',
        accessor: 'account_name',
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Label md gray2 capitalize>
              {row.original.attributes?.account_name}
            </Label>
          </div>
        ),
      },
      {
        Header: 'AMOUNT',
        accessor: 'amount',
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Label md gray2 capitalize>
              <FormattedNumber number={row.original.attributes?.amount} />
            </Label>
          </div>
        ),
      },
      {
        Header: 'DATE REQUESTED',
        accessor: 'data_requested',
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Label md gray2 capitalize>
              {row.original.attributes?.created_at}
            </Label>
          </div>
        ),
      },
      {
        Header: 'STATUS',
        accessor: 'payout_status',
        // eslint-disable-next-line react/destructuring-assignment
        Cell: ({ cell: { row } }) => {
          if (row.original.attributes.payout_status === 'pending') {
            return (
              <div className={styles?.cellWrapper}>
                <Badge pending>Pending</Badge>
              </div>
            );
          }
          if (row.original.attributes.payout_status === 'declined') {
            return (
              <div className={styles?.cellWrapper}>
                <Badge declined>Declined</Badge>
              </div>
            );
          }
          return (
            <div className={styles?.cellWrapper}>
              <Badge approved>Approved</Badge>
            </div>
          );
        },
      },
      {
        Header: ' ',
        accessor: '',
        Cell: ({ cell: { row } }) => {
          return (
            <div className={styles?.cellWrapper}>
              <Link to={`${SHORTFLIX_ROUTE.Payout}/${row.original.id}`}>
                <EllipsisVertical />
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <MainLayout title='Payout'>
      <Container style={{ maxWidth: 1700 }}>
        <div className={styles?.payoutContainer}>
          <div className={styles?.payoutContainer__top}>
            <Label black01 medium>
              PAYOUT
            </Label>
            <div className={styles?.selectSection}>
              <Search value={searchTerm} onChange={onSearch} />
              <Select
                values={FILTER_STATUS}
                selectedValue={filterStatus}
                onValueChange={(val) => onFilterStatus(val)}
              />
            </div>
          </div>
          <MainTable columns={columns} data={filteredPayoutData} isLoading={isLoading} />
        </div>
      </Container>
    </MainLayout>
  );
};

export default Index;
