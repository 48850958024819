import React from 'react';
import { Bar } from 'react-chartjs-2';

const ApprovalStatisticsGraph = ({
  approvalStatisticsApprovedData,
  approvalStatisticsDeclinedData,
}) => {
  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        position: 'top',
        display: true,
        text: 'Approval Statistics',
      },
    },
  };

  const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const barChart2Data = {
    labels,
    datasets: [
      {
        label: 'Approved',
        data: approvalStatisticsApprovedData,
        backgroundColor: '#AB1634',
      },
      {
        label: 'Pending',
        data: approvalStatisticsDeclinedData,
        backgroundColor: '#EFDF00',
      },
    ],
  };

  return <Bar options={options2} data={barChart2Data} />;
};

export default ApprovalStatisticsGraph;
