// import core and vendor packages below
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Badge from 'react-bootstrap/Badge';
import { NavLink, useLocation } from 'react-router-dom';

// import components below
import { Label } from 'components/GlobalUi/Typography';

// import style below
import styles from './index.module.scss';

// utils
import { SHORTFLIX_ROUTE } from 'utils/constants';

// assets
import { ReactComponent as Dashboard } from 'assets/svg/Dashboard.svg';
import { ReactComponent as DashboardActive } from 'assets/svg/Dashboard-active.svg';
import { ReactComponent as ContentApproval } from 'assets/svg/Content-approval.svg';
import { ReactComponent as ContentApprovalActive } from 'assets/svg/Content-approval-active.svg';
import { ReactComponent as List } from 'assets/svg/List.svg';
import { ReactComponent as ListActive } from 'assets/svg/List-active.svg';
import { ReactComponent as Analysis } from 'assets/svg/Analysis.svg';
import { ReactComponent as AnalysisActive } from 'assets/svg/Analysis-active.svg';
import { ReactComponent as Payout } from 'assets/svg/Payout.svg';
import { ReactComponent as PayoutActive } from 'assets/svg/Payout-active.svg';
import { ReactComponent as Faqs } from 'assets/svg/Faqs.svg';
import { ReactComponent as FaqsActive } from 'assets/svg/Faqs-active.svg';
import { ReactComponent as Lock } from 'assets/svg/Lock.svg';
import { ReactComponent as LockActive } from 'assets/svg/Lock-active.svg';

// redux
import { getPayoutNotification, selectPayout } from 'reducers/payoutReducer';
import { getCreatorNotification, selectApprovalList } from 'reducers/approvalListReducer';
import { getContentNotification, selectContent } from 'reducers/contentApprovalReducer';

// main component

const MenuItem = ({ id, name, href, icon, iconActive, count }) => {
  const location = useLocation();
  const match = location.pathname.startsWith(href);

  return (
    <div key={id} className={styles?.menuItem}>
      <NavLink to={href}>
        {match ? (
          <>
            <div className={styles?.iconWrapper}>
              {iconActive}
              {count !== 0 && (
                <Badge pill bg='danger' className={styles?.badge}>
                  {count}
                </Badge>
              )}
            </div>
            <Label sm primary>
              {name}
            </Label>
          </>
        ) : (
          <>
            <div className={styles?.iconWrapper}>
              {icon}
              {count !== 0 && (
                <Badge pill bg='danger' className={styles?.badge}>
                  {count}
                </Badge>
              )}
            </div>
            <Label sm gray>
              {name}
            </Label>
          </>
        )}
      </NavLink>
    </div>
  );
};
const SideBar = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPayoutNotification());
    dispatch(getContentNotification());
    dispatch(getCreatorNotification());
  }, []);

  const { payoutNotifCount } = useSelector(selectPayout);
  const { creatorNotifCount } = useSelector(selectApprovalList);
  const { contentNotifCount } = useSelector(selectContent);

  const menuItems = [
    {
      name: 'Dashboard',
      icon: <Dashboard />,
      iconActive: <DashboardActive />,
      href: SHORTFLIX_ROUTE.Dashboard,
    },
    {
      name: 'Content Approval',
      icon: <ContentApproval />,
      iconActive: <ContentApprovalActive />,
      href: SHORTFLIX_ROUTE.ContentApproval,
      count: contentNotifCount,
    },
    {
      name: 'Content Creators List',
      icon: <List />,
      iconActive: <ListActive />,
      href: SHORTFLIX_ROUTE.ContentCreatorsList,
    },
    {
      name: 'Approvals List',
      icon: <List />,
      iconActive: <ListActive />,
      href: SHORTFLIX_ROUTE.ApprovalsList,
      count: creatorNotifCount,
    },
    {
      name: 'App Analysis',
      icon: <Analysis />,
      iconActive: <AnalysisActive />,
      href: SHORTFLIX_ROUTE.AppAnalysis,
    },
    {
      name: 'Payout',
      icon: <Payout />,
      iconActive: <PayoutActive />,
      href: SHORTFLIX_ROUTE.Payout,
      count: payoutNotifCount,
    },
    {
      name: 'Shortflix Academy',
      icon: <Faqs />,
      iconActive: <FaqsActive />,
      href: SHORTFLIX_ROUTE.Faqs,
    },
    {
      name: 'Terms',
      icon: <Lock />,
      iconActive: <LockActive />,
      href: SHORTFLIX_ROUTE.Terms,
    },
  ];

  return (
    <aside className={styles?.layoutMenu}>
      <div className={styles?.innerBorder}>
        {menuItems.map((item, id) => (
          <MenuItem
            key={id}
            name={item.name}
            href={item.href}
            icon={item.icon}
            iconActive={item.iconActive}
            count={item.count}
          />
        ))}
      </div>
    </aside>
  );
};

export default SideBar;
