import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
  getApprovalStatisticsApproved,
  getApprovalStatisticsDeclined,
  getContentSubmission,
} from './thunks/dashboardThunks';

const initialState = {};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: {},
});

export const { actions: dashboardActions, reducer: dashboardReducer } = dashboardSlice;

const selectDomain = (state) => state.dashboard || initialState;

export const selectDashboard = createSelector([selectDomain], (state) => state);

export { getContentSubmission, getApprovalStatisticsApproved, getApprovalStatisticsDeclined };
