/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import { Container, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

// components
import MainLayout from 'components/Layout/Main';
import MainTable from 'components/GlobalUi/Table/table';
import { Label } from 'components/GlobalUi/Typography';
import ApprovalStatisticsGraph from 'components/UI/ApprovalStatisticsGraph';

// redux
import { getCreatorsList } from 'reducers/thunks/contentCreatorThunks';
import { selectContentCreator } from 'reducers/contentCreatorReducer';

// styles
import styles from './index.module.scss';
import {
  getApprovalStatisticsApproved,
  getApprovalStatisticsDeclined,
} from '../../reducers/thunks/dashboardThunks';

// assets
import { ReactComponent as DualRing } from 'assets/svg/Dual-Ring-red.svg';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const Index = () => {
  const dispatch = useDispatch();
  const { topCreatorData } = useSelector(selectContentCreator);
  const currentYear = new Date().getFullYear();

  const [approvalStatisticsLoading, setApprovalStatisticsLoading] = useState([]);
  const [approvalStatisticsApprovedData, setApprovalStatisticsApprovedData] = useState([]);
  const [approvalStatisticsDeclinedData, setApprovalStatisticsDeclinedData] = useState([]);

  useEffect(() => {
    dispatch(getCreatorsList());
  }, []);

  useEffect(() => {
    (async () => {
      let combinedApprovalStatisticsData = [];
      for (let i = 0; i < 12; i++) {
        const params = {
          date: i + 1,
          year: currentYear,
        };
        const resp = await dispatch(getApprovalStatisticsApproved(params));
        combinedApprovalStatisticsData = combinedApprovalStatisticsData.concat(resp.payload.data);
        setApprovalStatisticsLoading(true);
      }

      setApprovalStatisticsApprovedData(combinedApprovalStatisticsData);
      setApprovalStatisticsLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let combinedApprovalStatisticsData = [];
      for (let i = 0; i < 12; i++) {
        const params = {
          date: i + 1,
          year: currentYear,
        };
        const resp = await dispatch(getApprovalStatisticsDeclined(params));
        combinedApprovalStatisticsData = combinedApprovalStatisticsData.concat(resp.payload.data);
      }

      setApprovalStatisticsDeclinedData(combinedApprovalStatisticsData);
    })();
  }, []);

  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Number of Active Users',
      },
    },
  };

  const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const barChartData = {
    labels,
    datasets: [
      {
        label: 'Number of Active Users',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: '#AB1634',
      },
    ],
  };

  const columns = useMemo(
    () => [
      /* {
        Header: 'RANK',
        accessor: 'rank', // accessor is the "key" in the data
        Cell: () => (
          <div className={styles?.cellWrapper}>
            <Label md gray2 capitalize>
              1
            </Label>
          </div>
        ),
      }, */
      {
        Header: 'PROFILE PHOTO',
        accessor: 'selfie_id',
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Image src={row.original.attributes.selfie_id} className={styles?.imageWrapper} />
          </div>
        ),
      },
      {
        Header: 'NAME',
        accessor: 'full_name',
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Label md gray2 capitalize>
              {row.original.attributes.full_name}
            </Label>
          </div>
        ),
      },
      {
        Header: 'EMAIL',
        accessor: 'email',
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Label md gray2 capitalize>
              {row.original.attributes.email}
            </Label>
          </div>
        ),
      },
      {
        Header: 'PHONE NUMBER',
        accessor: 'mobile_number',
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Label md gray2 capitalize>
              +63{row.original.attributes.mobile_number}
            </Label>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <MainLayout title='App Analysis'>
      <Container style={{ maxWidth: 1700 }}>
        <div className={styles?.appAnalysisContainer}>
          <div className={styles?.activeUserCard}>
            <Bar options={options1} data={barChartData} />
          </div>
          <div className={styles?.approvalStaticCard}>
            {approvalStatisticsLoading && (
              <DualRing style={{ width: 30, height: 30, float: 'right' }} />
            )}
            <ApprovalStatisticsGraph
              approvalStatisticsApprovedData={approvalStatisticsApprovedData}
              approvalStatisticsDeclinedData={approvalStatisticsDeclinedData}
            />
          </div>
          <div className={styles?.topCreatorsContainer}>
            <div className={styles?.label}>
              <Label black01 medium>
                TOP CONTENT CREATORS
              </Label>
            </div>
            <MainTable columns={columns} data={topCreatorData} />
          </div>
        </div>
      </Container>
    </MainLayout>
  );
};

export default Index;
