import React from 'react';
import { Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
// styles
import styles from '../index.module.scss';

// components
import { Label } from 'components/GlobalUi/Typography';
import StyledSkeleton from 'components/GlobalUi/Skeleton/Skeleton';

// utils
import { SHORTFLIX_ROUTE } from 'utils/constants';

// assets
import { ReactComponent as AndgleRightGray } from 'assets/svg/Angle-right-gray.svg';

const ContentCreators = ({ activeCreatorLoading, activeCreatorListData }) => {
  const navigate = useNavigate();

  return (
    <div className={styles?.historyCard}>
      <div className={styles?.topTitleFlex}>
        <Label black01 md>
          Content Creators
        </Label>
        <Link to={SHORTFLIX_ROUTE.ContentCreatorsList}>
          <button>
            <Label gray3 xs>
              View All
            </Label>
          </button>
        </Link>
      </div>
      {activeCreatorLoading && <StyledSkeleton count={3} height={60} />}

      {activeCreatorListData.map((item) => {
        return (
          <div
            className={styles?.flexWrapper}
            key={item.id}
            onClick={() => navigate(`${SHORTFLIX_ROUTE.ContentCreatorsList}/${item.id}`)}
          >
            <div className={styles?.flex}>
              <Image
                src={item.attributes?.profile_pic}
                alt='profile pic'
                className={styles?.image}
              />
              <div>
                <Label sm gray3>
                  {item.attributes?.full_name}
                </Label>
                <Label xs success capitalize>
                  {item.attributes?.status} now
                </Label>
              </div>
            </div>
            <Link to={SHORTFLIX_ROUTE.ContentCreatorsList}>
              <AndgleRightGray />
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default ContentCreators;
