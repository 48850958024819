import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// components
import MainLayout from 'components/Layout/Main';
import Search from 'components/GlobalUi/Search/Search';
import Select from 'components/GlobalUi/Input/Select';
import MainTable from 'components/GlobalUi/Table/table';
import { Label } from 'components/GlobalUi/Typography';
import { Badge } from 'components/GlobalUi/Badge/Badge';

// styles
import styles from './index.module.scss';

// assets
import { ReactComponent as EllipsisVertical } from 'assets/svg/Ellipsis-vertical.svg';

// redux
import { getApprovalCreator, selectApprovalList } from 'reducers/approvalListReducer';

// utils
import { PAGES, SHORTFLIX_ROUTE } from 'utils/constants';

const Index = () => {
  const dispatch = useDispatch();
  const { approvalData, isLoading } = useSelector(selectApprovalList);

  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('');

  useEffect(() => {
    const params = {
      page: PAGES.CurrentPage,
      perPage: PAGES.PerPage,
    };
    dispatch(getApprovalCreator(params));
  }, []);

  const onSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCreatorData = useMemo(() => {
    return approvalData?.filter((item) => {
      return item.attributes.full_name.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [approvalData, searchTerm]);

  const onFilterStatus = (value) => {
    const params = {
      page: PAGES.CurrentPage,
      perPage: PAGES.PerPage,
      status: value,
    };
    dispatch(getApprovalCreator(params));
    setFilterStatus(value);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'PROFILE PHOTO',
        accessor: 'selfie_id', // accessor is the "key" in the data
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Image src={row.original.attributes.profile_pic} className={styles?.imageWrapper} />
          </div>
        ),
      },
      {
        Header: 'NAME',
        accessor: 'full_name',
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Label md gray2 capitalize>
              {row.original.attributes.full_name}
            </Label>
          </div>
        ),
      },
      {
        Header: 'EMAIL',
        accessor: 'email',
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Label md gray2 capitalize>
              {row.original.attributes.email}
            </Label>
          </div>
        ),
      },
      {
        Header: 'PHONE NUMBER',
        accessor: 'mobile_number',
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Label md gray2 capitalize>
              +63{row.original.attributes.mobile_number}
            </Label>
          </div>
        ),
      },
      {
        Header: 'STATUS',
        accessor: 'status',
        // eslint-disable-next-line react/destructuring-assignment
        Cell: ({ cell: { row } }) => {
          if (row.original.attributes.is_approved) {
            return (
              <div className={styles?.cellWrapper}>
                <Badge approved>Approve</Badge>
              </div>
            );
          }
          if (row.original.attributes.is_declined) {
            return (
              <div className={styles?.cellWrapper}>
                <Badge declined>Declined</Badge>
              </div>
            );
          }
          return (
            <div className={styles?.cellWrapper}>
              <Badge pending> Pending</Badge>
            </div>
          );
        },
      },
      {
        Header: ' ',
        accessor: '',
        Cell: ({ cell: { row } }) => {
          return (
            <div className={styles?.cellWrapper}>
              <Link to={`${SHORTFLIX_ROUTE.ApprovalsList}/${row.original.id}`}>
                <EllipsisVertical />
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );

  const FILTER_STATUS = [
    ['pending', 'Pending'],
    ['declined', 'Declined'],
  ];

  return (
    <MainLayout title='Approvals List'>
      <Container style={{ maxWidth: 1700 }}>
        <div className={styles?.approvalsContainer}>
          <div className={styles?.approvalsContainer__top}>
            <Label black01 medium>
              CONTENT CREATOR APPROVALS LIST
            </Label>
            <div className={styles?.selectSection}>
              <Search value={searchTerm} onChange={onSearch} />
              <Select
                values={FILTER_STATUS}
                selectedValue={filterStatus}
                onValueChange={(val) => onFilterStatus(val)}
              />
            </div>
          </div>
          <MainTable columns={columns} data={filteredCreatorData} isLoading={isLoading} />
        </div>
      </Container>
    </MainLayout>
  );
};

export default Index;
