// import core and vendor packages below
import React from 'react';

// import components below
import TopBar from '../Topbar';
import SideBar from '../Sidebar';
import ReactHelmet from 'components/GlobalUi/ReactHelmet';

// import style below
import styles from './index.module.scss';

// main component
const MainLayout = (props) => {
  const { children, title } = props;

  return (
    <>
      <ReactHelmet title={title} />
      <div className={styles?.layoutContainerNavbar}>
        <div className={styles?.layoutContainer}>
          <TopBar />
          <div className={styles?.layoutWrapper}>
            <SideBar />
            <div className={styles?.layoutPage}>
              <div>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
