import { configureStore } from '@reduxjs/toolkit';

import { sampleReducer } from 'reducers/sampleReducer';
import { authReducer } from 'reducers/authReducer';
import { dashboardReducer } from 'reducers/dashboardReducer';
import { contentCreatorReducer } from 'reducers/contentCreatorReducer';
import { contentApprovalReducer } from 'reducers/contentApprovalReducer';
import { approvalListReducer } from '../reducers/approvalListReducer';
import { payoutReducer } from 'reducers/payoutReducer';
import { faqsReducer } from 'reducers/faqsReducer';
import { termsReducer } from 'reducers/termsReducer';

export const store = configureStore({
  reducer: {
    sample: sampleReducer,
    auth: authReducer,
    dashboard: dashboardReducer,
    contentApproval: contentApprovalReducer,
    contentCreator: contentCreatorReducer,
    approvalList: approvalListReducer,
    payout: payoutReducer,
    faqs: faqsReducer,
    terms: termsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
