import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// components
import MainLayout from 'components/Layout/Main';
import CardContainer from 'components/GlobalUi/Card/CardContainer';
import Terms from './components/Terms';
import PrivacyPolicy from './components/PrivacyPolicy';
// import Button from 'components/GlobalUi/Button/Button';

// styles
import styles from './index.module.scss';

// redux
import { getTermsPolicy, selectTerms } from 'reducers/termsReducer';

// assets
import { ReactComponent as DualRing } from 'assets/svg/Dual-Ring-red.svg';

const Index = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading, termsDetails, policyDetails, termsPolicyData } = useSelector(selectTerms);
  /*  const TERMS_ID = 1;
  const POLICY_ID = 2; */

  console.log('termsPolicyData', termsPolicyData);
  console.log('termsDetails', termsDetails);

  useEffect(() => {
    (async () => {
      dispatch(getTermsPolicy());
    })();
  }, []);

  return (
    <MainLayout title='Terms'>
      <CardContainer>
        {!isLoading ? (
          <Terms preloadedData={termsDetails} />
        ) : (
          <div className={styles?.loaderWrapper}>
            <DualRing className={styles?.loader} />
          </div>
        )}
        {!isLoading && <PrivacyPolicy preloadedData={policyDetails} />}
        {/* <div className={styles?.btnWrapper__bottom}>
          <Button approve height50 onClick={handleSave}>
            Save
          </Button>
          <Button decline height50 onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </div> */}
      </CardContainer>
    </MainLayout>
  );
};

export default Index;
