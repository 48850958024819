import React from 'react';
import { Image } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

// components
import { Label } from 'components/GlobalUi/Typography';
import Button from 'components/GlobalUi/Button/Button';

// assets
import approvedImage from 'assets/images/approve.png';
// styles
import styles from './modal.module.scss';

const StyledModal = ({ show, title, onHide, approve, onApprove, onDecline, onChangeReason }) => {
  return (
    <Modal show={show} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header className={styles?.header}>
        <Modal.Title id='contained-modal-title-vcenter' as='h5'>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles?.body}>
        {approve ? (
          <>
            <Image src={approvedImage} alt='' className={styles?.approveImage} />
            <div>
              <Label black01 md center>
                Approve Content Creator?
              </Label>
            </div>
          </>
        ) : (
          <>
            <Label black01 md center>
              <Label black01 md center medium>
                Enter Reason of Disapproval
              </Label>
            </Label>
            <Form>
              <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                <Form.Control
                  as='textarea'
                  rows={4}
                  placeholder='Enter Reason Here'
                  onChange={onChangeReason}
                  required
                />
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer bsPrefix={styles.footer}>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {approve ? (
          <Button className={styles?.approve} onClick={onApprove}>
            <Label sm white>
              APPROVE
            </Label>
          </Button>
        ) : (
          <Button className={styles?.decline} onClick={onDecline}>
            <Label sm white>
              SUBMIT
            </Label>
          </Button>
        )}

        <Button className={styles?.cancel} onClick={onHide}>
          <Label sm white>
            CANCEL
          </Label>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StyledModal;
