import { Container } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const CardContainer = styled(Container).withConfig({
  shouldForwardProp: (prop) => !['noPaddingX'].includes(prop),
})`
  max-width: 1700px;
  padding: 33px 33px;

  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  opacity: 1;

  ${(props) =>
    props.noPaddingX &&
    css`
      padding: 33px 0;
    `};
`;

CardContainer.propTypes = {
  noPaddingX: PropTypes.bool,
};

export default CardContainer;
