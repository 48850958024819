import React from 'react';

import Modal from 'react-bootstrap/Modal';

// components
import { Label } from 'components/GlobalUi/Typography';
import Button from 'components/GlobalUi/Button/Button';

// styles
import styles from './modal.module.scss';

const RemoveConfirmationModal = ({ show, title, onHide, onRemove, question }) => {
  return (
    <Modal show={show} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header className={styles?.header}>
        <Modal.Title id='contained-modal-title-vcenter' as='h5'>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles?.body}>
        <Label black01 md center>
          Are you sure you want to remove{' '}
          <Label black01 medium>
            {question}
          </Label>
        </Label>
      </Modal.Body>
      <Modal.Footer bsPrefix={styles.footer}>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        <Button className={styles?.approve} onClick={onRemove}>
          <Label sm white>
            REMOVE
          </Label>
        </Button>

        <Button className={styles?.cancel} onClick={onHide}>
          <Label sm white>
            CANCEL
          </Label>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveConfirmationModal;
