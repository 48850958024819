import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

export const Title = styled.p.withConfig({
  shouldForwardProp: (prop) => !['xlTitle', 'lg', 'black01'].includes(prop),
})`
  letter-spacing: 0;
  font-weight: 500;
  font-size: 25px;
  color: #575757;

  padding: 0;
  margin: 0;

  ${(props) =>
    props.xlTitle &&
    css`
      font-weight: 600;
      font-size: 40px;
    `};

  ${(props) =>
    props.lg &&
    css`
      font-weight: 600;
      font-size: 25px;

      @media screen and (min-width: 1280px) {
        font-size: 25px;
      }

      @media screen and (min-width: 1440px) {
        font-size: 25px;
      }

      @media screen and (min-width: 1680px) {
        font-size: 30px;
      }

      @media screen and (min-width: 1920px) {
        font-size: 30px;
      }
    `};

  ${(props) =>
    props.black01 &&
    css`
      color: #1a1a1a;
    `};
`;

Title.propTypes = {
  xlTitle: PropTypes.bool,
  lg: PropTypes.bool,
  black01: PropTypes.bool,
};

export const Label = styled.p.withConfig({
  shouldForwardProp: (prop) =>
    ![
      'white',
      'gray',
      'gray2',
      'gray3',
      'sm',
      'md',
      'lg',
      'medium',
      'primary',
      'success',
      'link',
      'center',
      'black01',
      'capitalize',
    ].includes(prop),
})`
  letter-spacing: 0;
  font-weight: 400;
  font-size: 20px;
  color: #575757;
  line-break: auto;

  padding: 0;
  margin: 0;

  ${(props) =>
    props.white &&
    css`
      color: #fff;
    `};
  ${(props) =>
    props.gray &&
    css`
      color: #848484;
    `};
  ${(props) =>
    props.gray2 &&
    css`
      color: #2a2a2a;
    `};
  ${(props) =>
    props.black01 &&
    css`
      color: #1a1a1a;
    `};
  ${(props) =>
    props.gray3 &&
    css`
      color: #3f312d;
    `};

  ${(props) =>
    props.xs &&
    css`
      font-size: 13px;
    `};

  ${(props) =>
    props.sm &&
    css`
      font-size: 14px;

      @media screen and (min-width: 1280px) {
        font-size: 14px;
      }

      @media screen and (min-width: 1440px) {
        font-size: 15px;
      }
    `};

  ${(props) =>
    props.md &&
    css`
      font-size: 15px;

      @media screen and (min-width: 1280px) {
        font-size: 16px;
      }
      @media screen and (min-width: 1440px) {
        font-size: 18px;
      }
    `};

  ${(props) =>
    props.lg &&
    css`
      font-size: 25px;
    `};

  ${(props) =>
    props.medium &&
    css`
      font-weight: 600;
    `};

  ${(props) =>
    props.primary &&
    css`
      color: #ca2d2d;
    `};

  ${(props) =>
    props.success &&
    css`
      color: #02cb60;
    `};

  ${(props) =>
    props.link &&
    css`
      cursor: pointer;
    `};

  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `};
  ${(props) =>
    props.capitalize &&
    css`
      text-transform: capitalize;
    `};
`;

Label.propTypes = {
  white: PropTypes.bool,
  gray: PropTypes.bool,
  gray2: PropTypes.bool,
  gray3: PropTypes.bool,
  black01: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  medium: PropTypes.bool,
  primary: PropTypes.bool,
  success: PropTypes.bool,
  link: PropTypes.bool,
  center: PropTypes.bool,
  capitalize: PropTypes.bool,
};
