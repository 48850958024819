import React from 'react';
import styled from 'styled-components';

// styles
import styles from './select.module.scss';

// assets
import { ReactComponent as Funnel } from 'assets/svg/Funnel-grey.svg';

const Select = ({ values, onValueChange, selectedValue, ...rest }) => {
  const StyledSelect = styled.select.withConfig({
    shouldForwardProp: (prop) => ![].includes(prop),
  })`
    min-width: 285px;
    height: 50px;
    //background: #f2f5fa 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;

    outline: none;
    border: none;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 2px 20px 2px 2px;

    background-color: #f2f5fa;
    background-image: url('/Angle-down.svg');
    background-repeat: no-repeat;
    background-position: right 1rem center;
    //padding: 0 19px;
  `;

  return (
    <div className={styles?.selectParent}>
      <StyledSelect
        className={styles?.select}
        defaultValue={selectedValue}
        onChange={({ target: { value } }) => onValueChange(value)}
        {...rest}
      >
        {values.map(([value, text]) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </StyledSelect>
      <Funnel className={styles?.svgFunnel} />
    </div>
  );
};

export default Select;
