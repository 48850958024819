import { createSelector, createSlice } from '@reduxjs/toolkit';

import { getApprovalCreator, getCreatorNotification } from './thunks/approvalListThunk';

const initialState = {
  isLoading: false,
  approvalData: [],
  creatorNotifCount: 0,
};

const slice = createSlice({
  name: 'approvalList',
  initialState,
  reducers: {},
  extraReducers: {
    [getApprovalCreator.pending]: (state) => {
      state.isLoading = true;
    },
    [getApprovalCreator.fulfilled]: (state, action) => {
      state.approvalData = action.payload.data.filter(
        (item) => item.attributes.is_approved === false,
      );
      /*      let pendingCount = 0;
      for (let i = 0; i < action.payload.data.length; i++) {
        if (
          !action.payload.data[i]?.attributes.is_approved &&
          !action.payload.data[i]?.attributes.is_declined
        ) {
          pendingCount++;
        }
      }
      state.creatorPendingCount = pendingCount; */
      state.isLoading = false;
    },
    [getApprovalCreator.rejected]: (state, action) => {
      state.isLoading = false;
      console.log('rejected', action.payload);
    },
    [getCreatorNotification.pending]: (state) => {
      state.isLoading = true;
    },
    [getCreatorNotification.fulfilled]: (state, action) => {
      state.creatorNotifCount = parseInt(action.payload.data[0].toString());
      state.isLoading = false;
    },
    [getCreatorNotification.rejected]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
    },
  },
});

export const { reducer: approvalListReducer } = slice;

const selectDomain = (state) => state.approvalList || initialState;

export const selectApprovalList = createSelector([selectDomain], (state) => state);

export { getApprovalCreator, getCreatorNotification };
