/* eslint-disable camelcase */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

// utils
import { API } from 'utils/constants';

export const getFaqs = createAsyncThunk('FAQS_LIST', async (params, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    url: `${API.FAQS}`,
    params: {
      per_page: params.perPage,
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const addFaqs = createAsyncThunk('ADD_FAQS', async (params, { rejectWithValue }) => {
  return await axios({
    method: 'post',
    url: `${API.FAQS}`,
    data: {
      title: params.title,
      description: params.description,
      video: params.video,
    },
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const showFaq = createAsyncThunk('SHOW_FAQ', async (id, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    url: `${API.FAQS}/${id}`,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const removeFaq = createAsyncThunk('REMOVE_FAQ', async (id, { rejectWithValue }) => {
  return await axios({
    method: 'delete',
    url: `${API.FAQS}/${id}`,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const updateFaq = createAsyncThunk('UPDATE_FAQ', async (params, { rejectWithValue }) => {
  return await axios({
    method: 'post',
    url: `${API.UPDATE_FAQ}/${params.id}`,
    data: {
      title: params.title,
      description: params.description,
      video: params.video,
    },
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});
