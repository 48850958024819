/* eslint-disable camelcase */

import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { API } from 'utils/constants';

// eslint-disable-next-line no-unused-vars
export const getCreatorsList = createAsyncThunk(
  'CONTENT_CREATOR_LIST',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: API.CREATORS_LIST,
      params: {
        page: params.page,
        per_page: params.perPage,
        filter_status: params.status,
        roles: ['Content Creator'],
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getCreatorDetails = createAsyncThunk(
  'CONTENT_CREATOR_DETAILS',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `${API.CREATORS_LIST}/${id}`,
      params: {
        ['include[]']: 'user_balance',
      },
    })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getCreatorContents = createAsyncThunk(
  'CREATOR_CONTENTS',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `${API.CREATOR_CONTENTS}/${id}`,
      params: {
        include: ['user_content_video'],
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
export const getCreatorPayouts = createAsyncThunk(
  'CREATOR_PAYOUTS',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `${API.CREATOR_PAYOUTS}/${id}`,
    })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const removeCreator = createAsyncThunk('REMOVE_CREATOR', async (id, { rejectWithValue }) => {
  return await axios({
    method: 'delete',
    url: `${API.CREATORS_LIST}/${id}`,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});
