/* eslint-disable camelcase */

import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { API } from 'utils/constants';

// eslint-disable-next-line no-unused-vars
export const getContentList = createAsyncThunk(
  'CONTENT_LIST',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: API.CONTENT_LIST,
      params: {
        per_page: params.perPage,
        filter_status: params.status,
      },
    })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getContent = createAsyncThunk('CONTENT', async (id, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    url: `${API.CONTENT_DETAILS}/${id}`,
    params: {
      include: ['user_content_video'],
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const approveContent = createAsyncThunk(
  'CONTENT_APPROVE',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `${API.CONTENT_APPROVAL}/${params.userId}/${params.id}`,
    })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const declineContent = createAsyncThunk(
  'CONTENT_DECLINE',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `${API.CONTENT_DECLINE}/${params.userId}/${params.id}`,
      data: { decline_reason: params.declineReason },
    })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
export const getContentNotification = createAsyncThunk(
  'CONTENT_NOTIFICATION',
  async (_, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: API.CONTENT_NOTIFICATION,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
