import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// components
import MainLayout from 'components/Layout/Main';
import { Label } from 'components/GlobalUi/Typography';
import Search from 'components/GlobalUi/Search/Search';
import Select from 'components/GlobalUi/Input/Select';
import MainTable from 'components/GlobalUi/Table/table';
import { Badge } from 'components/GlobalUi/Badge/Badge';

// assets
import { ReactComponent as Play } from 'assets/svg/Play.svg';
import { ReactComponent as EllipsisVertical } from 'assets/svg/Ellipsis-vertical.svg';

// styles
import styles from './index.module.scss';

// redux
import { getCreatorsList, selectContent, getContentList } from 'reducers/contentApprovalReducer';

// utils
import { FILTER_STATUS, PAGES, SHORTFLIX_ROUTE } from 'utils/constants';

const Index = () => {
  const dispatch = useDispatch();
  const { contentListData, isLoadingContent } = useSelector(selectContent);

  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('');

  useEffect(() => {
    (async () => {
      const params = {
        page: PAGES.CurrentPage,
        perPage: PAGES.PerPage,
      };
      await dispatch(getContentList(params));
      dispatch(getCreatorsList(params));
    })();
  }, []);

  const onSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredContentData = React.useMemo(() => {
    return contentListData.filter((item) => {
      return item.attributes.title.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [contentListData, searchTerm]);

  const onFilterStatus = (value) => {
    const params = {
      page: PAGES.CurrentPage,
      perPage: PAGES.PerPage,
      status: value,
    };
    dispatch(getContentList(params));
    setFilterStatus(value);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'THUMBNAIL',
        accessor: 'thumbnail', // accessor is the "key" in the data
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Image src={row.original.attributes.thumbnail} />
            <Play className={styles?.svgPlay} />
          </div>
        ),
      },
      {
        Header: 'VIDEO TITLE',
        accessor: 'title',
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Label md gray2 capitalize>
              {row.original.attributes.title}
            </Label>
          </div>
        ),
      },
      {
        Header: 'SUBMITTED BY',
        accessor: 'col3',
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Label md gray2 capitalize>
              {row.original.fullName}
            </Label>
          </div>
        ),
      },
      {
        Header: 'DESCRIPTION',
        accessor: 'description',
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <div className={styles?.descriptionWidth}>
              <Label md gray2 capitalize>
                {row.original.attributes.description}
              </Label>
            </div>
          </div>
        ),
      },
      {
        Header: 'DURATION',
        accessor: 'duration',
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Label md gray2>
              {row.original.attributes.duration}
            </Label>
          </div>
        ),
      },
      {
        Header: 'CATEGORY',
        accessor: 'category',
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Label md gray2 capitalize>
              {row.original.attributes.category}
            </Label>
          </div>
        ),
      },
      {
        Header: 'STATUS',
        accessor: 'content_status',
        // eslint-disable-next-line react/destructuring-assignment
        Cell: ({ cell: { row } }) => {
          if (row.original.attributes.content_status === 'pending') {
            return (
              <div className={styles?.cellWrapper}>
                {<Badge pending>{row.original.attributes.content_status}</Badge>}
              </div>
            );
          }
          if (row.original.attributes.content_status === 'declined') {
            return (
              <div className={styles?.cellWrapper}>
                {<Badge declined>{row.original.attributes.content_status}</Badge>}
              </div>
            );
          }

          return (
            <div className={styles?.cellWrapper}>
              <Badge approved>{row.original.attributes.content_status}</Badge>
            </div>
          );
        },
      },
      {
        Header: ' ',
        accessor: '',
        Cell: ({ cell: { row } }) => {
          return (
            <div className={styles?.cellWrapper}>
              <Link
                to={`${SHORTFLIX_ROUTE.ContentApproval}/${row.original.id}?creator-id=${row.original.attributes?.user_id}`}
              >
                <EllipsisVertical />
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <MainLayout title='Content Approval List'>
      <Container style={{ maxWidth: 1700 }}>
        <div className={styles?.contentContainer}>
          <div className={styles?.contentContainer__top}>
            <Label black01 medium>
              CONTENT APPROVAL
            </Label>
            <div className={styles?.selectSection}>
              <Search value={searchTerm} onChange={onSearch} />
              <Select
                values={FILTER_STATUS}
                selectedValue={filterStatus}
                onValueChange={(val) => onFilterStatus(val)}
              />
            </div>
          </div>
          <div>
            <MainTable columns={columns} data={filteredContentData} isLoading={isLoadingContent} />
          </div>
        </div>
      </Container>
    </MainLayout>
  );
};

export default Index;
