import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
export const Badge = styled.div.withConfig({
  shouldForwardProp: (prop) => !['offline', 'pending', 'approved', 'declined'].includes(prop),
})`
  padding: 10px;
  color: #02cb60;
  text-align: center;
  font-size: 15px;

  max-width: 98px;

  background: #edf9f4 0% 0% no-repeat padding-box;
  text-transform: capitalize;

  ${(props) =>
    props.offline &&
    css`
      color: #848484;
      background: #f2f5fa 0% 0% no-repeat padding-box !important;
    `};

  ${(props) =>
    props.pending &&
    css`
      min-width: 103px;

      color: #c2b500;
      background: #fff9af 0% 0% no-repeat padding-box !important;
      border-radius: 8px;
    `};

  ${(props) =>
    props.approved &&
    css`
      min-width: 103px;

      color: #02cb60;
      background: #edf9f4 0% 0% no-repeat padding-box;
      border-radius: 8px;
    `};

  ${(props) =>
    props.declined &&
    css`
      min-width: 103px;

      color: #d1002a;
      background: #ffdfe6 0% 0% no-repeat padding-box;
      border-radius: 8px;
    `};
`;

Badge.propTypes = {
  offline: PropTypes.bool,
  pending: PropTypes.bool,
  approved: PropTypes.bool,
  declined: PropTypes.bool,
};
