/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from 'utils/constants';

export const getPayoutList = createAsyncThunk(
  'PAYOUT_LIST',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `${API.PAYOUT_LIST}`,
      params: {
        per_page: params.perPage,
        filter_status: params.status,
      },
    })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getPayoutDetails = createAsyncThunk(
  'PAYOUT_DETAILS',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `${API.PAYOUT_LIST}/${id}`,
    })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const approvePayout = createAsyncThunk(
  'PAYOUT_APPROVE',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `${API.PAYOUT_APPROVE}/${params.id}`,
      data: { proof_of_disbursement: params.proofOfDisbursement },
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const declinePayout = createAsyncThunk(
  'PAYOUT_DECLINE',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `${API.PAYOUT_DECLINE}/${params.id}`,
      data: { decline_reason: params.declineReason },
    })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getPayoutNotification = createAsyncThunk(
  'PAYOUT_NOTIFICATION',
  async (_, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: API.PAYOUT_NOTIFICATION,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
