import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

// components
import MainLayout from 'components/Layout/Main';
import { Label } from 'components/GlobalUi/Typography';
import Search from 'components/GlobalUi/Search/Search';
import MainTable from 'components/GlobalUi/Table/table';
import CardContainer from 'components/GlobalUi/Card/CardContainer';
import RemoveConfirmationModal from './components/modal';

// redux
import { getFaqs, selectFaqs } from 'reducers/faqsReducer';
import { removeFaq } from 'reducers/thunks/faqsThunk';

// utils
import { PAGES, SHORTFLIX_ROUTE } from 'utils/constants';

// styles
import styles from './index.module.scss';

// assets
import { ReactComponent as EllipsisVertical } from 'assets/svg/Ellipsis-vertical.svg';
import { ReactComponent as Plus } from 'assets/svg/Plus.svg';
import { ReactComponent as TrashBin } from 'assets/svg/Trash-bin.svg';

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, faqsData } = useSelector(selectFaqs);

  const [searchTerm, setSearchTerm] = useState('');
  const [isRemoveModalVisible, setRemoveModalVisible] = useState(false);
  const [faqId, setFaqId] = useState(0);
  const [question, setQuestion] = useState('');

  useEffect(() => {
    const params = {
      perPage: PAGES.PerPage,
    };
    dispatch(getFaqs(params));
  }, []);

  const onSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredFaqsData = useMemo(() => {
    return faqsData.filter((item) => {
      return item.attributes.title.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [faqsData, searchTerm]);

  const goToNewFaq = () => {
    navigate(SHORTFLIX_ROUTE.NewFaqs);
  };

  const handleRemoveModal = (id, question) => {
    setRemoveModalVisible(true);
    setFaqId(id);
    setQuestion(question);
  };

  const handleRemoveFaq = async () => {
    await dispatch(removeFaq(faqId));
    await toast.success('FAQ successfully remove', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      theme: 'light',
    });
    const params = {
      perPage: PAGES.PerPage,
    };
    await dispatch(getFaqs(params));

    setRemoveModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Question',
        accessor: 'title',
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Label md gray2 capitalize>
              {row.original.attributes?.title}
            </Label>
          </div>
        ),
      },
      {
        Header: 'Answer',
        accessor: 'description',
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <Label md gray2 capitalize>
              {row.original.attributes?.description}
            </Label>
          </div>
        ),
      },
      {
        Header: 'Action',
        accessor: '',
        Cell: ({ cell: { row } }) => (
          <div className={styles?.cellWrapper}>
            <TrashBin
              className={styles?.svgTrashBin}
              onClick={() => handleRemoveModal(row.original.id, row.original.attributes?.title)}
            />
          </div>
        ),
      },
      {
        Header: ' ',
        accessor: '',
        Cell: ({ cell: { row } }) => {
          return (
            <div className={styles?.ellipsisWrapper}>
              <Link to={`${SHORTFLIX_ROUTE.Faqs}/${row.original.id}`}>
                <EllipsisVertical />
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <MainLayout title='Shortflix Academy'>
      <CardContainer noPaddingX>
        <div className={styles?.faq__top}>
          <Label black01 medium>
            Shortflix Academy
          </Label>
          <div className={styles?.selectSection}>
            <button onClick={goToNewFaq}>
              <Plus />
              Add
            </button>
            <Search value={searchTerm} onChange={onSearch} />
          </div>
        </div>
        <MainTable columns={columns} data={filteredFaqsData} isLoading={isLoading} />
      </CardContainer>
      <RemoveConfirmationModal
        title='CONFIRM REMOVE FAQ'
        show={isRemoveModalVisible}
        onRemove={handleRemoveFaq}
        onHide={() => setRemoveModalVisible(false)}
        question={question}
      />
      <ToastContainer />
    </MainLayout>
  );
};

export default Index;
