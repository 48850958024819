import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

// components
import MainLayout from 'components/Layout/Main';
import { Label } from 'components/GlobalUi/Typography';
import Input from 'components/GlobalUi/Input/Input';
import Button from 'components/GlobalUi/Button/Button';
import CardContainer from 'components/GlobalUi/Card/CardContainer';

// redux
import { addFaqs } from 'reducers/thunks/faqsThunk';

// styles
import styles from './newFaq.module.scss';

// assets
import { ReactComponent as ClapperBoard } from 'assets/svg/Clapperboard.svg';

// utils
import faqsValidationSchema from '../validationSchema/faqsValidationSchema';

const NewFaq = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(faqsValidationSchema),
  });

  const videoFile = watch('video', false);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const params = {
        description: data.description,
        title: data.title,
        video: data.video[0],
      };
      console.log('params', params);
      await dispatch(addFaqs(params));
      reset();
      toast.success('FAQs added successfully.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light',
      });
      setLoading(false);
    } catch (err) {
      console.log('error', err);
      toast.error('Failed to add FAQ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light',
      });
    }
  };

  return (
    <MainLayout title='Faqs'>
      <CardContainer>
        <div className={styles?.newFaqContainer__top}>
          <Label black01 medium>
            New FAQ
          </Label>
        </div>
        <form className={styles?.formWrapper} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles?.formContainer}>
            <Label gray2 medium>
              Question
            </Label>
            <div className={styles?.input}>
              <Input
                placeholder='Enter question'
                id='title'
                type='text'
                name='title'
                {...register('title')}
                noBorder
                bgGray
                roundedLg
                paddingLgX
                black01
              />
              {errors.title && (
                <Label sm primary>
                  {errors.title.message}
                </Label>
              )}
            </div>
          </div>
          <div className={styles?.formContainer}>
            <Label gray2 medium>
              Answer
            </Label>
            <div>
              <textarea
                name='description'
                id='description'
                cols='30'
                {...register('description')}
                placeholder='Enter Answer'
              />
              {errors.description && (
                <Label sm primary>
                  {errors.description.message}
                </Label>
              )}
            </div>
          </div>
          <div className={styles?.formContainer}>
            <div className={styles?.videoWrapper}>
              <Label>Upload your video</Label>
              <div className={styles?.videoFields}>
                {videoFile?.[0] ? (
                  <video
                    src={URL.createObjectURL(videoFile[0])}
                    width='320'
                    height='240'
                    controls
                  />
                ) : (
                  <div className={styles?.svgClapperBoard}>
                    <ClapperBoard />
                  </div>
                )}
              </div>
              {}
              <label htmlFor='video' className={styles?.customFileUpload}>
                Browse
              </label>
              <input
                type='file'
                id='video'
                name='video'
                accept='video/*'
                {...register('video')}
                style={{ display: 'none' }}
              />
            </div>
          </div>
          <div className={styles?.btnWrapper}>
            <Button type='submit' approve height50 disabled={loading}>
              {loading ? 'Submitting' : 'Submit'}
            </Button>
            <Button decline height50 onClick={() => navigate(-1)}>
              Back
            </Button>
          </div>
        </form>
      </CardContainer>
      <ToastContainer />
    </MainLayout>
  );
};

export default NewFaq;
