import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Image, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

// components
import MainLayout from 'components/Layout/Main';
import { Label } from 'components/GlobalUi/Typography';
import Button from 'components/GlobalUi/Button/Button';
import StyledModal from './components/Modal';

// assets
import { ReactComponent as Envelope } from 'assets/svg/envelope.svg';
import { ReactComponent as Phone } from 'assets/svg/phone.svg';
import { ReactComponent as Globe } from 'assets/svg/globe.svg';

// import shortflixPhone from 'assets/images/shortflix_phone.png';
import { ReactComponent as ArrowLeft } from 'assets/svg/Arrow-left.svg';

// redux
import { getCreatorDetails, selectContentCreator } from 'reducers/contentCreatorReducer';
import { approveCreator, declineCreator } from 'reducers/thunks/approvalListThunk';

// styles
import styles from './details.module.scss';
import 'react-toastify/dist/ReactToastify.css';

const Details = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { creatorDetails } = useSelector(selectContentCreator);

  useEffect(() => {
    dispatch(getCreatorDetails(id));
  }, []);

  const [isModalApproveVisible, setModalApproveVisible] = useState(false);
  const [isModalDeclineVisible, setModalDeclineVisible] = useState(false);
  const [declineReason, setDeclineReason] = useState('');

  const handleApproveModal = () => {
    setModalApproveVisible(true);
  };
  const handleDeclineModal = () => {
    setModalDeclineVisible(true);
  };

  const handleApprove = async () => {
    await dispatch(approveCreator(id));
    await toast.success('Content creator verified successfully.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      theme: 'light',
    });
    setModalApproveVisible(false);
    dispatch(getCreatorDetails(id));
  };
  const handleDecline = async () => {
    const params = { id, declineReason };
    await dispatch(declineCreator(params));
    await toast.success('Content creator is declined.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      theme: 'light',
    });
    setModalDeclineVisible(false);
    dispatch(getCreatorDetails(id));
  };

  const RenderStatusButton = () => {
    if (creatorDetails.attributes?.is_declined) {
      return (
        <Button height50 approve onClick={handleApproveModal}>
          Approve
        </Button>
      );
    }
    if (
      creatorDetails.attributes?.is_approved &&
      creatorDetails.attributes?.is_declined === false
    ) {
      return false;
    }
    return (
      <>
        <Button height50 approve onClick={handleApproveModal}>
          Approve
        </Button>
        <Button height50 decline onClick={handleDeclineModal}>
          Decline
        </Button>
      </>
    );
  };

  return (
    <MainLayout title='Content Approval '>
      <div className={styles?.approvalDetailsContainer}>
        <div className={styles?.detailsTop}>
          <Label black01 medium>
            REVIEW CONTENT CREATOR
          </Label>
          <div className={styles?.goBack} onClick={() => navigate(-1)}>
            <ArrowLeft />
            <Label black01 medium>
              GO BACK
            </Label>
          </div>
        </div>
        <Row className={styles?.videoDetails}>
          <Col lg={4}>
            <div style={{ width: '100%' }}>
              <Image src={creatorDetails.attributes?.profile_pic} className={styles?.selfieId} />
            </div>
          </Col>
          <Col lg={7} className={styles?.descriptionWrapper}>
            <div>
              <Label black01 capitalize lg medium>
                {creatorDetails.attributes?.full_name}
              </Label>
              <Label black01 md className={styles?.labelWrapper}>
                {creatorDetails.attributes?.role}
              </Label>
            </div>
            <div className={styles?.subDescription}>
              <div>
                <Label black01 sm className={styles?.labelWrapper}>
                  <Envelope className={styles?.svgDetails} /> EMAIL
                </Label>
                <Label black01 sm className={styles?.labelWrapper}>
                  {creatorDetails.attributes?.email}
                </Label>
              </div>
              <div>
                <Label black01 sm className={styles?.labelWrapper}>
                  <Phone className={styles?.svgDetails} /> PHONE
                </Label>
                <Label black01 sm className={styles?.labelWrapper}>
                  +63{creatorDetails.attributes?.mobile_number}
                </Label>
              </div>
              <div>
                <Label black01 sm className={styles?.labelWrapper}>
                  <Globe className={styles?.svgDetails} /> COUNTRY
                </Label>
                <Label black01 sm className={styles?.labelWrapper}>
                  Philippines
                </Label>
              </div>
            </div>
            <div className={styles?.subDiv}>
              <Label sm medium gray2>
                Submitted on:
              </Label>
              <Label sm>{creatorDetails?.attributes?.created_at}</Label>
            </div>
            <div className={styles?.btnSection}>
              <RenderStatusButton />
            </div>
          </Col>
        </Row>
      </div>
      <StyledModal
        approve
        show={isModalApproveVisible}
        onApprove={handleApprove}
        title='APPROVE CONTENT CREATOR'
        onHide={() => setModalApproveVisible(false)}
      />
      <StyledModal
        show={isModalDeclineVisible}
        onDecline={handleDecline}
        title='DECLINE CONTENT CREATOR'
        onHide={() => setModalDeclineVisible(false)}
        onChangeReason={(e) => setDeclineReason(e.target.value)}
      />
      <ToastContainer />
    </MainLayout>
  );
};

export default Details;
