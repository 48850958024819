import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// utils
import { API } from 'utils/constants';

export const getTermsPolicy = createAsyncThunk('TERMS_POLICY', async (_, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    url: `${API.TERMS_POLICY}`,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});
export const addTerms = createAsyncThunk(
  'TERMS_AND_CONDITIONS',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `${API.TERMS_POLICY}`,
      params: {
        title: params.title,
        description: params.description,
        type: params.type,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const showTerms = createAsyncThunk(
  'SHOW_TERMS_AND_CONDITIONS',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `${API.TERMS_POLICY}/${id}`,
    })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updateTerms = createAsyncThunk('UPDATE_TERMS', async (params, { rejectWithValue }) => {
  return await axios({
    method: 'put',
    url: `${API.TERMS_POLICY}/${params.id}`,
    params: {
      title: params.title,
      description: params.description,
      type: params.type,
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const removeTerms = createAsyncThunk('REMOVE_TERMS', async (id, { rejectWithValue }) => {
  return await axios({
    method: 'delete',
    url: `${API.TERMS_POLICY}/${id}`,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

/* START POLICY  */

export const showPolicy = createAsyncThunk('SHOW_POLICY', async (id, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    url: `${API.TERMS_POLICY}/${id}`,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});
