import React from 'react';

function formatNumberWithCommas(number) {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function FormattedNumber({ number }) {
  const formattedNumber = formatNumberWithCommas(number);
  return <span>{formattedNumber}</span>;
}

export default FormattedNumber;
