import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Image, Row } from 'react-bootstrap';

// components
import Card from 'components/GlobalUi/Card/Card';
import { Label } from 'components/GlobalUi/Typography';
import { Badge } from 'components/GlobalUi/Badge/Badge';

// assets
import { ReactComponent as Eye } from 'assets/svg/Eye.svg';
import { ReactComponent as TrashBin } from 'assets/svg/Trash-bin.svg';

// utils
import { SHORTFLIX_ROUTE } from 'utils/constants';

// styles
import styles from '../index.module.scss';

const CreatorCard = ({
  image,
  fullName,
  email,
  mobileNumber,
  status,
  id,
  onClickRemoveCreator,
}) => {
  return (
    <Card>
      <div className={styles?.cardDiv}>
        <Row className={styles?.cardRow}>
          <Col className={styles?.col1}>
            <Image src={image} className={styles?.selfieId} />
            <div className={styles?.detailsWrapper}>
              <Label gray2 md>
                {fullName}
              </Label>
              <Label gray2 sm>
                {email}
              </Label>
              <Label gray2 sm>
                +63{mobileNumber}
              </Label>
              <div className={styles?.badgeWrapper}>
                <Badge offline={status === 'offline'}>{status}</Badge>
                <TrashBin className={styles?.svgTrashBin} onClick={onClickRemoveCreator} />
              </div>
            </div>
          </Col>
          <Col sm={109} className={styles?.col2}>
            <Link to={`${SHORTFLIX_ROUTE.ContentCreatorsList}/${id}`} relative='path'>
              <div style={{ backgroundColor: ' #D1002A', height: '100%' }}>
                <div className={styles?.svgEye}>
                  <Eye />
                </div>
              </div>
            </Link>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default CreatorCard;
