import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// utils
import { API } from 'utils/constants';

export const getContentSubmission = createAsyncThunk(
  'CONTENT_SUBMITTED',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `${API.CONTENT_SUBMITTED}`,
      data: { date: params.date, year: params.year },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getApprovalStatisticsApproved = createAsyncThunk(
  'APPROVAL_STATISTICS',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `${API.APPROVAL_STATISTICS}`,
      data: { date: params.date, year: params.year, status: 'approved' },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getApprovalStatisticsDeclined = createAsyncThunk(
  'APPROVAL_STATISTICS',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `${API.APPROVAL_STATISTICS}`,
      data: { date: params.date, year: params.year, status: 'pending' },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
