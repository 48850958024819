import React from 'react';

// components
import Input from '../Input/Input';

// assets
import { ReactComponent as Magnifier } from 'assets/svg/Magnifier.svg';

// styles
import styles from './search.module.scss';

const Search = ({ onChange }) => {
  return (
    <div className={styles?.searchParent}>
      <Input
        onChange={onChange}
        search
        noBorder
        className={styles?.search}
        placeholder='Search...'
        type='text'
      />
      <Magnifier className={styles?.svgMagnifier} />
    </div>
  );
};

export default Search;
