import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Col, Image, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import videojs from 'video.js';
import 'video.js/dist/video-js.css';

// components
import MainLayout from 'components/Layout/Main';
import { Label } from 'components/GlobalUi/Typography';
import Button from 'components/GlobalUi/Button/Button';
import VideoJS from 'components/GlobalUi/Video/Video';

// assets
import { ReactComponent as ArrowLeft } from 'assets/svg/Arrow-left.svg';
import { ReactComponent as DualRing } from 'assets/svg/Dual-Ring-red.svg';

// redux
import { getContent, selectContent, selectUserId } from 'reducers/contentApprovalReducer';
import { getCreatorDetails } from 'reducers/contentCreatorReducer';
import { approveContent, declineContent } from 'reducers/thunks/contentApprovalThunks';

// styles
import styles from './details.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import ContentModal from './components/Modal';

const Details = () => {
  const location = useLocation();
  const { id } = useParams();
  const params = new URLSearchParams(location.search);
  const creatorId = params.getAll('creator-id').toString();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { contentData, creatorDetails, isLoading, contentVideo } = useSelector(selectContent);
  const userId = useSelector(selectUserId);

  const [isModalApproveVisible, setModalApproveVisible] = useState(false);
  const [isModalDeclineVisible, setModalDeclineVisible] = useState(false);
  const [declineReason, setDeclineReason] = useState('');

  // video js
  const playerRef = React.useRef(null);

  console.log('contentVideo', contentVideo[0]?.attributes?.video_path_hls);

  const videoJsOptions = {
    height: 480,
    width: '100%',
    // fill: true,
    autoplay: false,
    controls: true,
    responsive: true,

    // fluid: true,
    sources: [
      {
        src: contentVideo[0]?.attributes?.video_path_dash,
        type: 'application/dash+xml',
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

  useEffect(() => {
    (async () => {
      await dispatch(getContent(id));
      dispatch(getCreatorDetails(creatorId));
    })();
  }, []);

  const handleApproveModal = () => {
    setModalApproveVisible(true);
  };
  const handleDeclineModal = () => {
    setModalDeclineVisible(true);
  };

  const handleApprove = async () => {
    const params = { userId, id };
    await dispatch(approveContent(params));
    await toast.success('Content video is approved.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      theme: 'light',
    });
    setModalApproveVisible(false);
    dispatch(getContent(id));
  };
  const handleDecline = async () => {
    const params = { userId, id, declineReason };
    await dispatch(declineContent(params));
    await toast.success('These Content video is declined by admin.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      theme: 'light',
    });
    setModalDeclineVisible(false);
    dispatch(getContent(id));
  };

  return (
    <MainLayout title='Content Approval '>
      <div className={styles?.detailsContainer}>
        <div className={styles?.detailsTop}>
          <Label black01 medium>
            REVIEW CONTENT SUBMISSION
          </Label>
          <div className={styles?.goBack} onClick={() => navigate(-1)}>
            <ArrowLeft />
            <Label black01 medium>
              GO BACK
            </Label>
          </div>
        </div>
        {isLoading ? (
          <DualRing className={styles?.svgDualRing} />
        ) : (
          <Row className={styles?.videoDetails}>
            <Col style={{ position: 'relative' }}>
              <div>
                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
              </div>
            </Col>
            <Col className={styles?.descriptionWrapper}>
              <Label primary capitalize lg medium>
                {contentData?.attributes?.title}
              </Label>
              <Label sm medium gray2>
                Description:
              </Label>
              <div style={{ minHeight: 150 }}>
                <Label sm gray2>
                  {contentData?.attributes?.description}
                </Label>
              </div>
              <div className={styles?.subDescription}>
                <div className={styles?.subDiv}>
                  <Label sm medium gray2>
                    Duration:
                  </Label>
                  <Label sm> {contentData?.attributes?.duration}</Label>
                </div>
                <div className={styles?.subDiv}>
                  <Label sm medium gray2>
                    Audience:
                  </Label>
                  <Label sm>{contentData?.attributes?.audience}</Label>
                </div>
                <div className={styles?.subDiv}>
                  <Label sm medium gray2>
                    Category:
                  </Label>
                  <Label sm>{contentData?.attributes?.category}</Label>
                </div>
                <div className={styles?.subDiv}>
                  <Label sm medium gray2>
                    Content Creator:
                  </Label>
                  <Label sm>
                    <Image
                      src={creatorDetails?.attributes?.profile_pic}
                      className={styles?.profileImage}
                    />
                    {creatorDetails?.attributes?.full_name}
                  </Label>
                </div>
                <div className={styles?.subDiv}>
                  <Label sm medium gray2>
                    Submitted on:
                  </Label>
                  <Label sm>{contentData?.attributes?.created_at}</Label>
                </div>
                <div className={styles?.subDiv}>
                  <Label sm medium gray2>
                    Attempts:
                  </Label>
                  <Label sm>{contentData?.attributes?.attempts || 1}</Label>
                </div>
              </div>

              <div className={styles?.btnSection}>
                {contentData?.attributes?.content_status === 'pending' && (
                  <>
                    <Button height50 approve onClick={handleApproveModal}>
                      Approve
                    </Button>
                    <Button height50 decline onClick={handleDeclineModal}>
                      Decline
                    </Button>
                  </>
                )}
                {contentData?.attributes?.content_status === 'declined' && (
                  <Button height50 approve onClick={handleApproveModal}>
                    Approve
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        )}
      </div>
      <ContentModal
        approve
        show={isModalApproveVisible}
        onApprove={handleApprove}
        title='APPROVE CONTENT SUBMISSION'
        onHide={() => setModalApproveVisible(false)}
      />
      <ContentModal
        show={isModalDeclineVisible}
        onDecline={handleDecline}
        title='DECLINE CONTENT SUBMISSION'
        onHide={() => setModalDeclineVisible(false)}
        onChangeReason={(e) => setDeclineReason(e.target.value)}
      />
      <ToastContainer />
    </MainLayout>
  );
};

export default Details;
