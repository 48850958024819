import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  approveContent,
  getContent,
  getContentList,
  getContentNotification,
} from './thunks/contentApprovalThunks';
import { getCreatorDetails, getCreatorsList } from './thunks/contentCreatorThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  isLoadingContent: false,
  isLoading: false,
  contentListData: [],
  creatorListData: [],
  contentNotifCount: 0,
  contentData: {
    attributes: {
      title: '',
      description: '',
      duration: '',
      category: '',
      // eslint-disable-next-line camelcase
      created_at: '',
      // eslint-disable-next-line camelcase
      user_id: '',
    },
  },
  contentVideo: [],
  creatorDetails: {},
  approve: {},
  logs: initialLog,
  submitLogs: initialLog,
  updateLogs: initialLog,
  deleteLogs: initialLog,
};

const slice = createSlice({
  name: 'contentApproval',
  initialState,
  reducers: {},
  extraReducers: {
    [getCreatorsList.pending]: (state) => {
      state.isLoading = true;
    },
    [getCreatorsList.fulfilled]: (state, action) => {
      state.creatorListData = action.payload.data;
      state.isLoading = false;
    },
    [getCreatorsList.rejected]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
    },

    [getContentList.pending]: (state) => {
      state.isLoadingContent = true;
    },
    [getContentList.fulfilled]: (state, action) => {
      state.contentListData = action.payload.data.map((content) => {
        const user = state.creatorListData.find((user) => user.id === content?.attributes?.user_id);
        return {
          ...content,
          fullName: user?.attributes?.full_name,
          profilePic: user?.attributes?.profile_pic,
        };
      });
      /*   let pendingCount = 0;
      for (let i = 0; i < action.payload.data.length; i++) {
        if (action.payload.data[i]?.attributes.content_status === 'pending') {
          pendingCount++;
        }
      } */
      // state.contentPendingCount = pendingCount;

      state.isLoadingContent = false;
    },
    [getContentList.rejected]: (state, action) => {
      console.log(action.payload);
      state.isLoadingContent = false;
    },
    [getContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getContent.fulfilled]: (state, action) => {
      state.contentData = action.payload.data.data;
      state.contentVideo = action.payload.data.included;
      state.isLoading = false;
    },
    [getContent.rejected]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
    },

    [getCreatorDetails.pending]: (state) => {
      state.isLoading = true;
    },
    [getCreatorDetails.fulfilled]: (state, action) => {
      state.creatorDetails = action.payload.data;
      state.isLoading = false;
    },
    [getCreatorDetails.rejected]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
    },

    [approveContent.pending]: (state) => {
      state.isLoading = true;
    },
    [approveContent.fulfilled]: (state, action) => {
      state.approve = action.payload.data;
      state.isLoading = false;
    },
    [approveContent.rejected]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
    },
    [getContentNotification.pending]: (state) => {
      state.isLoading = true;
    },
    [getContentNotification.fulfilled]: (state, action) => {
      state.contentNotifCount = parseInt(action.payload.data[0].toString());
      state.isLoading = false;
    },
    [getContentNotification.rejected]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
    },
  },
});

export const { actions: contentApprovalActions, reducer: contentApprovalReducer } = slice;

const selectDomain = (state) => state.contentApproval || initialState;

export const selectContent = createSelector([selectDomain], (state) => state);
export const selectUserId = createSelector(
  [selectDomain],
  (state) => state.contentData.attributes.user_id,
);

export { getContentList, getContent, getCreatorsList, getContentNotification };
