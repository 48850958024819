import React from 'react';
import Modal from 'react-bootstrap/Modal';

// styles
import styles from './modal.module.scss';

const StyledModal = ({ show, title, children }) => {
  return (
    <Modal show={show} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header className={styles?.header}>
        <Modal.Title id='contained-modal-title-vcenter' as='h5'>
          {title}
        </Modal.Title>
      </Modal.Header>
      {children}
    </Modal>
  );
};

export default StyledModal;
