import React from 'react';
import styles from '../index.module.scss';

// assets
import { ReactComponent as AngleLeft } from 'assets/svg/Angle-left.svg';
import { ReactComponent as AngleRight } from 'assets/svg/Angle-right.svg';
const Pagination = ({ goToPage, backToPage, currentPage, totalPages }) => {
  return (
    <div className={styles?.pagination}>
      <div className={styles?.paginationWrapper}>
        <button className={styles?.pageButton} onClick={backToPage} disabled={currentPage <= 1}>
          <AngleLeft />
        </button>
        <button
          onClick={goToPage}
          className={styles?.pageButton}
          disabled={currentPage >= totalPages}
        >
          <AngleRight />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
