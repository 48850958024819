import React from 'react';
import { Col } from 'react-bootstrap';

// styles
import styles from '../details.module.scss';

// components
import Skeleton from 'components/GlobalUi/Skeleton/Skeleton';
import { Label } from 'components/GlobalUi/Typography';

const LoadingSkeleton = () => {
  return (
    <>
      <Col lg={3}>
        <Skeleton className={styles?.selfieId} />
      </Col>
      <Col className={styles?.descriptionWrapper}>
        <div className={styles?.skeletonTitle}>
          <Skeleton height={25} noMargin />
        </div>
        <div className={styles?.subDescription}>
          <div className={styles?.subDiv}>
            <Label medium sm gray2>
              ACCOUNT NUMBER
            </Label>
            <Label black01 sm className={styles?.labelWrapper}>
              <Skeleton height={25} className={styles?.skeleton} />
            </Label>
          </div>{' '}
          <div className={styles?.subDiv}>
            <Label medium sm gray2>
              AMOUNT
            </Label>
            <Label black01 sm className={styles?.labelWrapper}>
              <Skeleton height={25} className={styles?.skeleton} />
            </Label>
          </div>
          <div className={styles?.subDiv}>
            <Label medium sm gray2>
              BANK
            </Label>
            <Label black01 sm capitalize className={styles?.labelWrapper}>
              <Skeleton height={25} className={styles?.skeleton} />
            </Label>
          </div>
          <div className={styles?.subDiv}>
            <Label sm medium gray2>
              Submitted on:
            </Label>
            <Label black01 sm className={styles?.labelWrapper}>
              <Skeleton height={25} className={styles?.skeleton} />
            </Label>
          </div>
        </div>
      </Col>
    </>
  );
};

export default LoadingSkeleton;
