// import core and vendor packages below
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// import components below
import { Label } from 'components/GlobalUi/Typography';

// import style below
import styles from './index.module.scss';

// utils
import { getParsed, removeItem } from 'utils/storage';

// assets
import { ReactComponent as HamburgerMenu } from 'assets/svg/Hamburger-menu.svg';
import { ReactComponent as Logout } from 'assets/svg/Logout.svg';
import logoSmall from 'assets/images/Logo-small.png';
import emptyUser from 'assets/images/Empty-user.png';

import { logout } from 'reducers/authReducer';
import AccountService from 'services/accountService';
import { SHORTFLIX_ROUTE } from '../../../utils/constants';

// main component
function DateTimeToday() {
  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Label md white>
        Today is <span>{currentTime.format('MMMM DD, YYYY | h:mm a')}</span>
      </Label>
      <p></p>
    </div>
  );
}

const TopBar = () => {
  const dispatch = useDispatch();
  const userInfo = getParsed('shortflix_user_info');

  const logOut = async () => {
    removeItem('accessToken');
    AccountService.clearAuth();
    dispatch(logout());
  };

  return (
    <nav className={styles?.layoutNavbar}>
      <div className={styles?.layoutWrapper}>
        <HamburgerMenu />
        <div className={styles?.layoutDiv}>
          <div className={styles?.welcomeDiv}>
            <Link to={SHORTFLIX_ROUTE.Dashboard}>
              <Image src={logoSmall} />
            </Link>
            <Label md white>
              Welcome, {userInfo?.first_name}
            </Label>
          </div>
          <div>
            <DateTimeToday />
          </div>
          <div className={styles?.logoutWrapper}>
            <div className={styles?.logoutDiv}>
              <Image src={emptyUser} className={styles?.svgHamburgerMenu} />
              <Label md white>
                {userInfo?.full_name}
              </Label>
            </div>
            <Logout onClick={logOut} className={styles?.svgLogout} />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default TopBar;
