import React, { useState } from 'react';
import { ReactComponent as Funnel } from 'assets/svg/Funnel.svg';
import { Label } from '../Typography';

// styles
import styles from './dropDown.module.scss';

function ButtonDropdown({ options, onSelect }) {
  const [isOpen, setIsOpen] = useState(false); // Set the default state of the dropdown to closed
  const [selectedOption, setSelectedOption] = useState(
    options && options.length > 0 ? options[0] : '',
  );

  console.log('selectOption', selectedOption);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (value) => {
    setSelectedOption(value);
    toggleDropdown();
    onSelect(value);
  };

  return (
    <div className={styles?.dropDownContainer}>
      <Funnel className={styles?.svgFunnel} onClick={toggleDropdown} />
      {isOpen && (
        <div className={styles?.optionContainer}>
          {options.map(([option, text]) => (
            <div
              key={option}
              onClick={() => handleOptionSelect(option)}
              className={styles?.optionWrapper}
            >
              <Label gray2 sm>
                {text}
              </Label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ButtonDropdown;
