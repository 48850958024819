import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import videojs from 'video.js';

// components
import MainLayout from 'components/Layout/Main';
import { Label, Title } from 'components/GlobalUi/Typography';
import { Badge } from 'components/GlobalUi/Badge/Badge';
import VideoJS from 'components/GlobalUi/Video/Video';
import ButtonDropdown from 'components/GlobalUi/Dropdown/Dropdown';

// assets
import theater from 'assets/images/theater.png';
import maya from 'assets/images/maya.jpg';
import gCash from 'assets/images/gcash.jpeg';
import emptyUser from 'assets/images/Empty-user.png';
import { ReactComponent as Envelope } from 'assets/svg/envelope.svg';
import { ReactComponent as DualRing } from 'assets/svg/Dual-Ring-red.svg';
import { ReactComponent as Phone } from 'assets/svg/phone.svg';
import { ReactComponent as Video } from 'assets/svg/video.svg';
import { ReactComponent as Globe } from 'assets/svg/globe.svg';
import { ReactComponent as Wallet } from 'assets/svg/Wallet.svg';

// styles
import styles from './details.module.scss';

// redux
import {
  getCreatorDetails,
  getCreatorContents,
  getCreatorPayouts,
} from 'reducers/thunks/contentCreatorThunks';
import { selectContentCreator } from 'reducers/contentCreatorReducer';

// utils
import { FILTER_PAYOUT, FILTER_STATUS } from 'utils/constants';
import FormattedNumber from 'helpers/formattedNumber';

const Details = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    creatorDetails,
    creatorContents,
    creatorPayouts,
    isLoading,
    isLoadingPayouts,
    creatorVideoData,
    createDetailsIncluded,
  } = useSelector(selectContentCreator);

  useEffect(() => {
    (async () => {
      await dispatch(getCreatorDetails(id));
      await dispatch(getCreatorContents(id));
    })();
  }, []);
  useEffect(() => {
    (async () => {
      dispatch(getCreatorPayouts(id));
    })();
  }, []);

  const [filterStatus, setFilterStatus] = useState('');
  const [filterPayout, setFilterPayout] = useState('');
  const [withdrawable, setWithdrawable] = useState(0);

  const combineContents = creatorContents.map((item, index) => ({
    ...item,
    included: creatorVideoData[index],
  }));

  const filteredCreatorContentsData = useMemo(() => {
    return combineContents.filter((item) => {
      return item.attributes?.content_status.toLowerCase().includes(filterStatus);
    });
  }, [combineContents, filterStatus]);

  const filteredPayoutData = useMemo(() => {
    return creatorPayouts.filter((item) => {
      return item.attributes?.payment_channel.includes(filterPayout);
    });
  }, [creatorPayouts, filterPayout]);

  // video js
  const playerRef = useRef(null);
  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

  /*  const RenderBadge = ({ status }) => {
    return (
      <div>
        {/!* eslint-disable-next-line no-nested-ternary *!/}
        {status === 'pending' ? (
          <Badge pending>Pending</Badge>
        ) : status === 'declined' ? (
          <Badge declined>Declined</Badge>
        ) : (
          <Badge>Approved</Badge>
        )}
      </div>
    );
  }; */

  function handleOptionSelect(selectedOption) {
    setFilterStatus(selectedOption);
  }

  function handleOptionPayout(selectedOption) {
    setFilterPayout(selectedOption);
  }

  useEffect(() => {
    console.log('createDetailsIncluded', createDetailsIncluded);
    if (createDetailsIncluded && createDetailsIncluded.length > 0) {
      const wallet = createDetailsIncluded.find((d) => d?.type === 'wallets');

      console.log('WALLET', wallet);

      if (wallet) {
        setWithdrawable(wallet?.attributes?.balance);
      }
    }
  }, [createDetailsIncluded]);

  return (
    <MainLayout title='Content Creator List'>
      <Container bsPrefix={styles?.profileContainer}>
        <div className={styles?.profileContainer}>
          <div style={{ width: '100%' }}>
            <Image
              className={styles?.banner}
              src={
                creatorDetails.attributes?.profile_banner
                  ? creatorDetails.attributes?.profile_banner
                  : theater
              }
            />
          </div>
          <div className={styles?.wrapper}>
            <div className={styles?.cardDetails}>
              <div className={styles?.cardDetailsFlex}>
                <div className={styles?.imageWrapper}>
                  <Image
                    src={
                      creatorDetails.attributes?.profile_pic
                        ? creatorDetails.attributes?.profile_pic
                        : emptyUser
                    }
                    className={styles?.selfieId}
                  />
                </div>
                <div style={{ width: '88%' }}>
                  <Title black01 lg medium>
                    {creatorDetails.attributes?.full_name}
                  </Title>
                  <Label black01 className={styles?.labelWrapper}>
                    {creatorDetails.attributes?.role}
                  </Label>
                </div>
                <div className={styles?.groupFlex}>
                  <div>
                    <Label black01 sm className={styles?.labelWrapper}>
                      <Envelope className={styles?.svgDetails} /> EMAIL
                    </Label>
                    <Label black01 sm className={styles?.labelWrapper}>
                      {creatorDetails.attributes?.email}
                    </Label>
                  </div>
                  <div>
                    <Label black01 sm className={styles?.labelWrapper}>
                      <Phone className={styles?.svgDetails} /> PHONE
                    </Label>
                    <Label black01 sm className={styles?.labelWrapper}>
                      +63{creatorDetails.attributes?.mobile_number}
                    </Label>
                  </div>{' '}
                  <div>
                    <Label black01 sm className={styles?.labelWrapper}>
                      <Wallet className={styles?.svgDetails} /> AVAILABLE BALANCE
                    </Label>
                    <Label black01 sm className={styles?.labelWrapper}>
                      {creatorDetails.attributes?.available_balance || 0}
                    </Label>
                  </div>
                </div>
                <div className={styles?.groupFlex}>
                  <div>
                    <Label black01 sm className={styles?.labelWrapper}>
                      <Video className={styles?.svgDetails} />
                      TOTAL FOLLOWERS
                    </Label>
                    <Label black01 sm className={styles?.labelWrapper}>
                      {creatorDetails.attributes?.total_followers || 0}
                    </Label>
                  </div>
                  <div>
                    <Label black01 sm className={styles?.labelWrapper}>
                      <Video className={styles?.svgDetails} />
                      TOTAL VIEW CONTENT
                    </Label>
                    <Label black01 sm className={styles?.labelWrapper}>
                      {creatorDetails.attributes?.total_content_views || 0}
                    </Label>
                  </div>
                  <div>
                    <Label black01 sm className={styles?.labelWrapper}>
                      <Wallet className={styles?.svgDetails} /> WITHDRAW-ABLE BALANCE
                    </Label>
                    <Label black01 sm className={styles?.labelWrapper}>
                      {withdrawable || 0}
                    </Label>
                  </div>
                </div>
                <div className={styles?.groupFlex}>
                  <div>
                    <Label black01 sm className={styles?.labelWrapper}>
                      <Video className={styles?.svgDetails} /> TOTAL CONTENT LIKES
                    </Label>
                    <Label black01 sm className={styles?.labelWrapper}>
                      {creatorDetails.attributes?.total_content_likes || 0}
                    </Label>
                  </div>
                  <div>
                    <Label black01 sm className={styles?.labelWrapper}>
                      <Globe className={styles?.svgDetails} /> COUNTRY
                    </Label>
                    <Label black01 sm className={styles?.labelWrapper}>
                      Philippines
                    </Label>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles?.contentSection}>
              <Row>
                <Col sm={4}>
                  <div className={styles?.payoutHistory}>
                    <div>
                      <div className={styles?.top}>
                        <Label gray2 md>
                          PAYOUT HISTORY
                        </Label>
                        <ButtonDropdown options={FILTER_PAYOUT} onSelect={handleOptionPayout} />
                      </div>
                      <div className={styles?.history__details}>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {isLoadingPayouts ? (
                          <DualRing className={styles?.loader} />
                        ) : filteredPayoutData.length > 0 ? (
                          filteredPayoutData.map((item, id) => {
                            return (
                              <div key={id} className={styles?.history}>
                                <Image
                                  src={
                                    item.attributes?.payment_channel === 'maya' ||
                                    item.attributes?.payment_channel === 'Maya'
                                      ? maya
                                      : gCash
                                  }
                                  style={{ width: 70, height: 70, borderRadius: 10 }}
                                />
                                <div className={styles?.history__date}>
                                  <Label gray2 sm medium>
                                    Php <FormattedNumber number={item.attributes?.amount} />
                                  </Label>
                                  <Label gray2 sm>
                                    {item.attributes?.created_at}
                                  </Label>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <Label sm center>
                            No Payout History
                          </Label>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={8}>
                  <div className={styles?.filterContent}>
                    <Label gray2 md>
                      FILTER CONTENTS
                    </Label>
                    <ButtonDropdown options={FILTER_STATUS} onSelect={handleOptionSelect} />
                  </div>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isLoading ? (
                    <DualRing className={styles?.loader} />
                  ) : filteredCreatorContentsData.length > 0 ? (
                    filteredCreatorContentsData?.map((item, id) => {
                      return (
                        <div className={styles?.cardFeed} key={id}>
                          <div>
                            <div className={styles?.feedWrapper}>
                              <div className={styles?.topDetails}>
                                <Image
                                  src={
                                    creatorDetails.attributes?.profile_pic
                                      ? creatorDetails.attributes?.profile_pic
                                      : emptyUser
                                  }
                                  className={styles?.cardFeed_selfieId}
                                />
                                <div>
                                  <Label md black01 medium>
                                    {creatorDetails.attributes?.full_name}
                                  </Label>
                                  <Label md black01>
                                    {item.attributes?.created_at}
                                  </Label>
                                </div>
                              </div>
                              {/* eslint-disable-next-line no-nested-ternary */}
                              {item.attributes?.content_status === 'pending' ? (
                                <Badge pending>{item.attributes?.content_status}</Badge>
                              ) : item.attributes?.content_status === 'declined' ? (
                                <Badge declined>{item.attributes?.content_status}</Badge>
                              ) : (
                                <Badge>{item.attributes?.content_status}</Badge>
                              )}
                            </div>
                            <div className={styles?.videoSection}>
                              <VideoJS
                                options={{
                                  autoplay: false,
                                  controls: true,
                                  responsive: true,
                                  sources: [
                                    {
                                      src: item?.included?.attributes?.video_path_dash,
                                      type: 'application/dash+xml',
                                    },
                                  ],
                                }}
                                onReady={handlePlayerReady}
                              />
                            </div>
                            <div className={styles?.titleSection}>
                              <Label gray2 medium>
                                {item.attributes?.title}
                              </Label>
                            </div>
                            <div className={styles?.descriptionSection}>
                              <Label gray2 medium>
                                Description:
                              </Label>
                              <Label gray2 sm>
                                <div className={styles?.description}>
                                  {item.attributes?.description}
                                </div>
                              </Label>
                              <div className={styles?.durationSection}>
                                <Video className={styles?.svgDetails} />
                                <Label md gray2 medium>
                                  {item.attributes?.duration}
                                </Label>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <Label sm center>
                      No Content
                    </Label>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </MainLayout>
  );
};

export default Details;
