import React from 'react';
import { Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
// styles
import styles from '../index.module.scss';

// components
import { Label } from 'components/GlobalUi/Typography';
import StyledSkeleton from 'components/GlobalUi/Skeleton/Skeleton';

// utils
import { SHORTFLIX_ROUTE } from 'utils/constants';

// assets
import { ReactComponent as AndgleRightGray } from 'assets/svg/Angle-right-gray.svg';

const ApprovalHistory = ({ isLoading, contentListData }) => {
  const navigate = useNavigate();

  return (
    <div className={styles?.historyCard}>
      <div className={styles?.topTitleFlex}>
        <Label black01 md>
          Approval History
        </Label>
        <Link to={SHORTFLIX_ROUTE.ContentApproval}>
          <button>
            <Label gray3 xs>
              View All
            </Label>
          </button>
        </Link>
      </div>
      {isLoading && <StyledSkeleton count={3} height={60} />}

      {contentListData.slice(0, 6).map((item) => {
        return (
          <div
            className={styles?.flexWrapper}
            key={item.id}
            onClick={() =>
              navigate(
                `${SHORTFLIX_ROUTE.ContentApproval}/${item.id}?creator-id=${item.attributes?.user_id}`,
              )
            }
          >
            <div className={styles?.flex}>
              <Image src={item.attributes?.thumbnail} alt='thumbnail' className={styles?.image} />
              <div>
                <Label sm gray3>
                  {item.attributes?.title}
                </Label>
                <Label xs gray3>
                  {item.attributes?.created_at}
                </Label>
              </div>
            </div>
            <AndgleRightGray />
          </div>
        );
      })}
    </div>
  );
};

export default ApprovalHistory;
