const Url = process.env.REACT_APP_API_BASE_URL
  ? `${process.env.REACT_APP_API_BASE_URL}`
  : `https://develop.shortflix-be.highlysucceed.com`;

export const API = {
  AUTH_LOGIN: `${Url}/api/login`,
  APPROVAL_STATISTICS: `${Url}/api/admin/dashboard/approval/statistics`,
  CONTENT_SUBMITTED: `${Url}/api/admin/dashboard/content/submitted`,
  CONTENT_LIST: `${Url}/api/admin/contents`,
  CONTENT_DETAILS: `${Url}/api/admin/content/show`,
  CONTENT_APPROVAL: `${Url}/api/admin/content/approval`,
  CONTENT_DECLINE: `${Url}/api/admin/content/decline`,
  CONTENT_NOTIFICATION: `${Url}/api/admin/content/count/notification`,
  CREATOR_CONTENTS: `${Url}/api/admin/contents`,
  CREATORS_LIST: `${Url}/api/users`,
  CREATOR_PAYOUTS: `${Url}/api/admin/payouts/user`,
  CREATORS_DETAILS: `${Url}/api/users/:id`,
  CREATOR_APPROVE: `${Url}/api/admin/creator/approval`,
  CREATOR_DECLINE: `${Url}/api/admin/creator/decline`,
  CREATOR_NOTIFICATION: `${Url}/api/admin/creator/count/notification`,
  PAYOUT_LIST: `${Url}/api/admin/payouts`,
  PAYOUT_DETAILS: `${Url}/api/admin/payouts/:id`,
  PAYOUT_APPROVE: `${Url}/api/admin/payout/approval`,
  PAYOUT_DECLINE: `${Url}/api/admin/payout/decline`,
  PAYOUT_NOTIFICATION: `${Url}/api/admin/payout/count/notification`,
  FAQS: `${Url}/api/admin/faqs`,
  UPDATE_FAQ: `${Url}/api/admin/faq/update`,
  TERMS_POLICY: `${Url}/api/admin/policy`,
};

export const SHORTFLIX_ROUTE = {
  Login: '/login',
  ForgotPassword: '/forgot-password',
  ResetPassword: '/reset-password',
  SignUp: '/sign-up',
  Dashboard: '/dashboard',
  ContentApproval: '/content-approval',
  ContentApprovalDetails: '/content-approval/:id',
  ContentCreatorsList: '/content-creator-list',
  ContentCreatorDetails: '/content-creator-list/:id',
  ApprovalsList: '/approvals-list',
  ApprovalsDetails: '/approvals-list/:id',
  AppAnalysis: '/app-analysis',
  Payout: '/payout',
  PayoutDetails: '/payout/:id',
  Faqs: '/shortflix-academy',
  FaqsDetails: '/shortflix-academy/:id',
  NewFaqs: '/shortflix-academy/add',
  Terms: '/terms',
};

export const COOKIE_PREFIX = 'shortflix_';
export const COOKIE_EXPIRES_DAYS = 1;

export const FILTER_STATUS = [
  ['pending', 'Pending'],
  ['approved', 'Approved'],
  ['declined', 'Declined'],
];

export const FILTER_PAYOUT = [
  ['Gcash', 'Gcash'],
  ['maya', 'Maya'],
];

export const PAGES = {
  CurrentPage: 0,
  PerPage: 1000000,
};

export const TYPES = {
  Terms: 'terms',
  Policy: 'policy',
};
