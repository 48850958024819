/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// components
import MainLayout from 'components/Layout/Main';

// redux
import { getContentList } from 'reducers/thunks/contentApprovalThunks';
import { getCreatorsList } from 'reducers/thunks/contentCreatorThunks';
import {
  getApprovalStatisticsApproved,
  getApprovalStatisticsDeclined,
  getContentSubmission,
} from 'reducers/thunks/dashboardThunks';
import { selectContent } from 'reducers/contentApprovalReducer';
import { selectContentCreator } from 'reducers/contentCreatorReducer';

// styles
import styles from './index.module.scss';

// assets
import { ReactComponent as DualRing } from 'assets/svg/Dual-Ring-red.svg';

// utils
import ApprovalHistory from './components/ApprovalHistory';
import ContentCreators from './components/ContentCreators';
import ContentSubmissionGraph from './components/ContentSubmissionGraph';
import ApprovalStatisticsGraph from 'components/UI/ApprovalStatisticsGraph';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Index = () => {
  const dispatch = useDispatch();
  const { contentListData, isLoading } = useSelector(selectContent);
  const { activeCreatorListData, isLoading: activeCreatorLoading } =
    useSelector(selectContentCreator);

  const currentYear = new Date().getFullYear();
  const [isSubmissionLoading, setSubmissionLoading] = useState(false);
  const [isApprovalApprovedLoading, setApprovalApprovedLoading] = useState(false);
  const [contentSubmissionData, setContenSubmissionData] = useState([]);
  const [approvalStatisticsApprovedData, setApprovalStatisticsApprovedData] = useState([]);
  const [approvalStatisticsDeclinedData, setApprovalStatisticsDeclinedData] = useState([]);

  useEffect(() => {
    (async () => {
      const params = {
        page: 0,
        per_page: 10000,
      };
      dispatch(getContentList('pending'));
      dispatch(getCreatorsList(params));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let combinedSubmissionData = [];
      for (let i = 0; i < 12; i++) {
        const params = {
          date: i + 1,
          year: currentYear,
        };
        const resp = await dispatch(getContentSubmission(params));
        combinedSubmissionData = combinedSubmissionData.concat(resp.payload.data);
        setSubmissionLoading(true);
      }

      setContenSubmissionData(combinedSubmissionData);
      setSubmissionLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let combinedApprovalStatisticsData = [];
      for (let i = 0; i < 12; i++) {
        const params = {
          date: i + 1,
          year: currentYear,
        };
        const resp = await dispatch(getApprovalStatisticsApproved(params));
        combinedApprovalStatisticsData = combinedApprovalStatisticsData.concat(resp.payload.data);
        setApprovalApprovedLoading(true);
      }

      setApprovalStatisticsApprovedData(combinedApprovalStatisticsData);
      setApprovalApprovedLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let combinedApprovalStatisticsData = [];
      for (let i = 0; i < 12; i++) {
        const params = {
          date: i + 1,
          year: currentYear,
        };
        const resp = await dispatch(getApprovalStatisticsDeclined(params));
        combinedApprovalStatisticsData = combinedApprovalStatisticsData.concat(resp.payload.data);
      }

      setApprovalStatisticsDeclinedData(combinedApprovalStatisticsData);
    })();
  }, []);

  return (
    <MainLayout title='Dashboard'>
      <Container style={{ maxWidth: 1700 }}>
        <div className={styles?.grid__container}>
          <div className={styles?.dashboardCard}>
            {isSubmissionLoading && <DualRing style={{ width: 30, height: 30, float: 'right' }} />}
            <ContentSubmissionGraph contentSubmissionData={contentSubmissionData} />
          </div>
          <ApprovalHistory isLoading={isLoading} contentListData={contentListData} />
          <div className={styles?.dashboardCard}>
            {isApprovalApprovedLoading && (
              <DualRing style={{ width: 30, height: 30, float: 'right' }} />
            )}
            <ApprovalStatisticsGraph
              approvalStatisticsApprovedData={approvalStatisticsApprovedData}
              approvalStatisticsDeclinedData={approvalStatisticsDeclinedData}
            />
          </div>
          <ContentCreators
            activeCreatorLoading={activeCreatorLoading}
            activeCreatorListData={activeCreatorListData}
          />
        </div>
      </Container>
    </MainLayout>
  );
};

export default Index;
