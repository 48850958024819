import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { toast, ToastContainer } from 'react-toastify';

// components
import MainLayout from 'components/Layout/Main';
import CardContainer from 'components/GlobalUi/Card/CardContainer';
import { Label } from 'components/GlobalUi/Typography';
import Input from 'components/GlobalUi/Input/Input';
import Button from 'components/GlobalUi/Button/Button';

// redux
import { selectFaqs, showFaq } from 'reducers/faqsReducer';
import { updateFaq } from 'reducers/thunks/faqsThunk';

// styles
import styles from './newFaq.module.scss';

// assets
import { ReactComponent as ArrowLeft } from 'assets/svg/Arrow-left.svg';

// validation
import faqsValidationSchema from '../validationSchema/faqsValidationSchema';

const ViewFaq = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isLoading, faqDetails } = useSelector(selectFaqs);

  // Use useController to manage the state of a form field

  const {
    handleSubmit,
    // reset,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(faqsValidationSchema),
  });

  useEffect(() => {
    dispatch(showFaq(id));
  }, []);

  useEffect(() => {
    setValue('title', faqDetails?.attributes?.title);
    setValue('description', faqDetails?.attributes?.description);
  }, [faqDetails, setValue]);

  const onSubmit = async () => {
    const params = {
      title: watch('title'),
      description: watch('description'),
      id: id,
    };
    dispatch(updateFaq(params));
    await toast.success('FAQ successfully remove', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      theme: 'light',
    });
  };

  return (
    <MainLayout title='View Faq'>
      <CardContainer>
        <div className={styles?.newFaqContainer__top}>
          <Label black01 medium>
            View FAQ
          </Label>
          <div className={styles?.goBack} onClick={() => navigate(-1)}>
            <ArrowLeft />
            <Label black01 medium>
              GO BACK
            </Label>
          </div>
        </div>
        {!isLoading && (
          <form className={styles?.formWrapper} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles?.formContainer}>
              <Label gray2 medium>
                Question
              </Label>
              <div className={styles?.input}>
                <Controller
                  control={control}
                  name='title'
                  render={({ field }) => (
                    <Input
                      id='title'
                      type='text'
                      name='title'
                      {...field}
                      noBorder
                      bgGray
                      roundedLg
                      paddingLgX
                      black01
                      placeholder='Enter question'
                    />
                  )}
                />
                {errors.title && (
                  <Label sm primary>
                    {errors.title.message}
                  </Label>
                )}
              </div>
            </div>
            <div className={styles?.formContainer}>
              <Label gray2 medium>
                Answer
              </Label>
              <div>
                <Controller
                  control={control}
                  name='description'
                  render={({ field }) => (
                    <textarea
                      name='description'
                      {...field}
                      id='description'
                      cols='30'
                      placeholder='Enter Answer'
                    />
                  )}
                />
              </div>
            </div>
            {!faqDetails.attributes?.video_path_dash === '' && (
              <div className={styles?.formContainer}>
                <div className={styles?.videoWrapper}>
                  <div className={styles?.videoFields}>
                    <video
                      src={faqDetails.attributes?.video_path_dash}
                      width='320'
                      height='240'
                      controls
                    />
                  </div>
                </div>
              </div>
            )}

            <div className={styles?.btnWrapper}>
              <Button approve height50 type='submit'>
                Update
              </Button>
            </div>
          </form>
        )}
      </CardContainer>
      <ToastContainer />
    </MainLayout>
  );
};

export default ViewFaq;
