import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Image, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

// styles
import styles from './details.module.scss';
import 'react-toastify/dist/ReactToastify.css';

// components
import MainLayout from 'components/Layout/Main';
import { Label } from 'components/GlobalUi/Typography';
import Button from 'components/GlobalUi/Button/Button';
import StyledModal from './components/Modal';
import { Badge } from 'components/GlobalUi/Badge/Badge';

// assets
import { ReactComponent as ArrowLeft } from 'assets/svg/Arrow-left.svg';

// redux
import { selectPayout } from 'reducers/payoutReducer';
import { approvePayout, declinePayout, getPayoutDetails } from 'reducers/thunks/payoutListThunk';

// utils
import FormattedNumber from 'helpers/formattedNumber';
import LoadingSkeleton from './components/LoadingSkeleton';

import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const Details = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { payoutDetails, isLoading } = useSelector(selectPayout);

  const handlePayout = () => {
    dispatch(getPayoutDetails(id));
  };

  useEffect(() => {
    handlePayout();
  }, []);

  const [isModalApproveVisible, setModalApproveVisible] = useState(false);
  const [isModalDeclineVisible, setModalDeclineVisible] = useState(false);
  const [proofOfDisbursement, setProofOfDisbursement] = useState(null);
  const [image, setImage] = useState(null);
  const [declineReason, setDeclineReason] = useState();
  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleApproveModal = () => {
    setModalApproveVisible(true);
  };
  const handleDeclineModal = () => {
    setModalDeclineVisible(true);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (allowedTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      setLoading(true);

      reader.onload = () => {
        const imageData = reader.result;

        setError(null);
        setLoading(false);
        setImage(imageData);
        setProofOfDisbursement(file);
      };
    } else {
      setError('The selected file is not an image in one of the supported formats.');
    }
  };

  const handleApprove = async () => {
    const params = { id, proofOfDisbursement };
    const response = await dispatch(approvePayout(params));

    if (response?.meta?.requestStatus === 'rejected') {
      if (response?.payload?.errors) {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      toast.error('Something went wrong.');
      return;
    }

    if (response) {
      toast.success('Content creator verified successfully.', {
        theme: 'light',
        autoClose: 5000,
        closeOnClick: true,
        position: 'top-right',
        hideProgressBar: false,
      });

      handlePayout();
      setModalApproveVisible(false);
    }
  };

  const handleDecline = async () => {
    const params = { id, declineReason };
    const response = await dispatch(declinePayout(params));

    if (response?.meta?.requestStatus === 'rejected') {
      if (response?.payload?.errors) {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      toast.error('Something went wrong.');
      return;
    }

    if (response) {
      toast.success('Content creator is declined.', {
        theme: 'light',
        autoClose: 5000,
        closeOnClick: true,
        position: 'top-right',
        hideProgressBar: false,
      });

      handlePayout();
      setModalDeclineVisible(false);
    }
  };

  const RenderStatusBadge = () => {
    if (payoutDetails[0]?.attributes?.payout_status === 'approved') {
      return <Badge approved>{payoutDetails[0]?.attributes?.payout_status}</Badge>;
    }
    if (payoutDetails[0]?.attributes?.payout_status === 'declined') {
      return <Badge declined>{payoutDetails[0]?.attributes?.payout_status}</Badge>;
    }
    return <Badge pending>{payoutDetails[0]?.attributes?.payout_status}</Badge>;
  };

  const RenderProof = () => {
    if (payoutDetails[0]?.attributes?.payout_status === 'approved') {
      return (
        <div className={styles?.subDescription}>
          <div className={styles?.subDiv}>
            <Label sm medium gray2>
              SELFIE ID
            </Label>

            <Label black01 sm className={styles?.labelWrapper}>
              <Zoom>
                <img
                  alt='proof of disbursement'
                  src={payoutDetails[0]?.attributes?.selfie_id}
                  width='500'
                  className={styles?.selfieId}
                />
              </Zoom>
            </Label>
          </div>
          <div className={styles?.subDiv}>
            <Label sm medium gray2>
              PROOF OF DISBURSEMENT
            </Label>
            <Label black01 sm className={styles?.labelWrapper}>
              <Zoom>
                <img
                  alt='proof of disbursement'
                  src={payoutDetails[0]?.attributes?.proof_of_disbursement}
                  width='500'
                  className={styles?.selfieId}
                />
              </Zoom>
            </Label>
          </div>
        </div>
      );
    }
    if (payoutDetails[0]?.attributes?.payout_status === 'declined') {
      return (
        <>
          <Label sm medium gray2>
            REASON
          </Label>
          <Label black01 sm className={styles?.labelWrapper}>
            {payoutDetails[0]?.attributes?.decline_reason}
          </Label>
        </>
      );
    }
    return false;
  };

  return (
    <MainLayout title='Content Approval '>
      <div className={styles?.approvalDetailsContainer}>
        <div className={styles?.detailsTop}>
          <Label black01 medium>
            REVIEW PAYOUT
          </Label>
          <div className={styles?.goBack} onClick={() => navigate(-1)}>
            <ArrowLeft />
            <Label black01 medium>
              GO BACK
            </Label>
          </div>
        </div>
        <Row className={styles?.videoDetails}>
          {isLoading ? (
            <LoadingSkeleton />
          ) : (
            <>
              <Col lg={3}>
                <Image
                  src={payoutDetails[0]?.attributes?.profile_pic}
                  className={styles?.selfieId}
                />
              </Col>
              <Col className={styles?.descriptionWrapper}>
                <div>
                  <Label black01 capitalize lg medium>
                    {payoutDetails[0]?.attributes?.account_name}
                  </Label>
                </div>
                <div className={styles?.subDescription}>
                  <div className={styles?.subDiv}>
                    <Label medium sm gray2>
                      ACCOUNT NUMBER
                    </Label>
                    <Label black01 sm className={styles?.labelWrapper}>
                      {payoutDetails[0]?.attributes?.account_number}
                    </Label>
                  </div>{' '}
                  <div className={styles?.subDiv}>
                    <Label medium sm gray2>
                      AMOUNT
                    </Label>
                    <Label black01 sm className={styles?.labelWrapper}>
                      <FormattedNumber number={payoutDetails[0]?.attributes?.amount} />
                    </Label>
                  </div>
                  <div className={styles?.subDiv}>
                    <Label medium sm gray2>
                      BANK
                    </Label>
                    <Label black01 sm capitalize className={styles?.labelWrapper}>
                      {payoutDetails[0]?.attributes?.payment_channel}
                    </Label>
                  </div>
                  <div className={styles?.subDiv}>
                    <Label sm medium gray2>
                      Submitted on:
                    </Label>
                    <Label black01 sm className={styles?.labelWrapper}>
                      {payoutDetails[0]?.attributes?.created_at}
                    </Label>
                  </div>
                  <div className={styles?.subDiv}>
                    <Label sm medium gray2>
                      Status
                    </Label>
                    <RenderStatusBadge />
                  </div>
                </div>
                <div>
                  <RenderProof />
                </div>
                <div className={styles?.btnSection}>
                  {payoutDetails[0]?.attributes?.payout_status === 'pending' && (
                    <Button
                      height50
                      approve={payoutDetails[0]?.attributes?.payout_status !== 'declined'}
                      onClick={handleApproveModal}
                    >
                      Approve
                    </Button>
                  )}
                  {payoutDetails[0]?.attributes?.payout_status === 'pending' && (
                    <Button height50 decline onClick={handleDeclineModal}>
                      Decline
                    </Button>
                  )}
                </div>
              </Col>
            </>
          )}
        </Row>
      </div>
      <StyledModal
        approve
        show={isModalApproveVisible}
        onApprove={handleApprove}
        title='APPROVE PAYOUT REQUEST'
        onHide={() => setModalApproveVisible(false)}
        onChangeProofOfDisbursement={handleImageUpload}
        imageData={image}
        loading={loading}
        error={error}
      />
      <StyledModal
        show={isModalDeclineVisible}
        onDecline={handleDecline}
        title='DECLINE PAYOUT REQUEST'
        onHide={() => setModalDeclineVisible(false)}
        onChangeReason={(e) => setDeclineReason(e.target.value)}
      />
      <ToastContainer />
    </MainLayout>
  );
};

export default Details;
