import React, { useEffect, useState } from 'react';
import { Col, Row, Image, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

// css
import styles from './index.module.scss';

// components
import Button from 'components/GlobalUi/Button/Button';
import { Label, Title } from 'components/GlobalUi/Typography';
import Input from 'components/GlobalUi/Input/Input';
import ReactHelmet from 'components/GlobalUi/ReactHelmet';

// assets
import Logo from 'assets/images/Logo.png';
import ShortFlixPhone from 'assets/images/shortflix_phone.png';

// redux
import { login, selectAuth } from 'reducers/authReducer';
import loginValidationSchema from './validation/loginValidationSchema';

const Login = () => {
  const dispatch = useDispatch();
  const { error, showError, isLoading } = useSelector(selectAuth);

  const [isError, setIsError] = useState({});

  useEffect(() => {
    setIsError(error);
  }, [error]);

  const { message = [] } = isError;
  const { detail = '' } = message[0] || {};

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidationSchema),
  });
  const onSubmit = (data) => {
    dispatch(login(data));
  };

  const RenderError = ({ detail }) => {
    return showError ? (
      <Alert className={styles?.error} variant={`danger`}>
        {detail}
      </Alert>
    ) : null;
  };

  return (
    <>
      <ReactHelmet title='login' />
      <Row className={styles?.row}>
        <Col className={styles?.col} align='center'>
          <div className={styles?.colDiv}>
            <div>
              <Image src={Logo} alt='short flix' />
              <Title xlTitle>Welcome Back</Title>
              <Title>Hello there, sign in to continue</Title>
            </div>
            <form className={styles?.signInForm} onSubmit={handleSubmit(onSubmit)}>
              <div className={styles?.signInWrapper}>
                <RenderError detail={detail} />
                <Input
                  underline
                  placeholder='Email Address'
                  type='username'
                  {...register('username')}
                />
                {errors.username && (
                  <Label sm primary>
                    {errors.username.message}
                  </Label>
                )}
              </div>
              <div className={styles?.signInWrapper}>
                <Input
                  underline
                  placeholder='Password'
                  type='password'
                  name='password'
                  {...register('password')}
                />
                {errors.password && (
                  <Label sm primary>
                    {errors.password.message}
                  </Label>
                )}
                {/* hide for now */}
                {/* <div className={styles.forgotPasswordLabel}>
                  <Link to={SHORTFLIX_ROUTE.ForgotPassword}>
                    <Label primary link>
                      Forgot Password?
                    </Label>
                  </Link>
                </div> */}
              </div>

              <Button primary type='submit' disabled={isLoading}>
                {isLoading ? <Label white>Signing in ...</Label> : <Label white>Sign in</Label>}
              </Button>
            </form>
            {/* hide for now */}
            {/* <div className={styles?.signUpWrapper}>
               eslint-disable-next-line react/no-unescaped-entities 
              <Label sm>Don't have an account?</Label>
              <div className={styles.svgWrapper}>
                <Link to={SHORTFLIX_ROUTE.SignUp}>
                  <Label sm primary link>
                    Sign Up
                  </Label>
                </Link>
                <CaretRight style={{ marginLeft: 5 }} />
              </div>
            </div> */}
          </div>
        </Col>
        <Col className={styles?.col} align='center'>
          <Image
            className={styles?.shortFlixTheaterImage}
            src={ShortFlixPhone}
            alt='short flix phone'
          />
        </Col>
      </Row>
    </>
  );
};

export default Login;
