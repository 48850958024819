import React from 'react';
import { Image } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

// components
import Button from 'components/GlobalUi/Button/Button';
import { Label } from 'components/GlobalUi/Typography';
import StyledModal from 'components/GlobalUi/Modal/Modal';

// assets
import approvedImage from 'assets/images/approve.png';

// styles
import styles from './modal.module.scss';

const ContentModal = ({ show, title, onHide, approve, onApprove, onDecline, onChangeReason }) => {
  return (
    <StyledModal show={show} title={title}>
      <Modal.Body className={styles?.body}>
        {approve ? (
          <>
            <Image src={approvedImage} alt='' className={styles?.approveImage} />
            <div>
              <Label black01 md center>
                Approve video content?
              </Label>
              <Label black01 md center>
                (Note: please check for guideline misuse on the whole video duration)
              </Label>
            </div>
          </>
        ) : (
          <>
            <Label black01 md center>
              <Label black01 md center medium>
                Enter Reason of Disapproval
              </Label>
            </Label>
            <Form>
              <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                <Form.Control
                  as='textarea'
                  rows={4}
                  placeholder='Enter Reason Here'
                  onChange={onChangeReason}
                  required
                />
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer bsPrefix={styles.footer}>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {approve ? (
          <Button className={styles?.approve} onClick={onApprove}>
            <Label sm white>
              APPROVE
            </Label>
          </Button>
        ) : (
          <Button className={styles?.decline} onClick={onDecline}>
            <Label sm white>
              SUBMIT
            </Label>
          </Button>
        )}

        <Button className={styles?.cancel} onClick={onHide}>
          <Label sm white>
            CANCEL
          </Label>
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
};

export default ContentModal;
