import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { toast, ToastContainer } from 'react-toastify';

// styles
import styles from '../index.module.scss';

// components
import { Label } from 'components/GlobalUi/Typography';
import Input from 'components/GlobalUi/Input/Input';
import Button from 'components/GlobalUi/Button/Button';

// redux
import { addTerms, getTermsPolicy } from 'reducers/termsReducer';
import { removeTerms, updateTerms } from 'reducers/thunks/termsThunk';

// validation
import termsValidationSchema from '../validationSchema';

// utils
import { TYPES } from 'utils/constants';

const PrivacyPolicy = ({ preloadedData }) => {
  const dispatch = useDispatch();

  const {
    handleSubmit,
    // reset,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(termsValidationSchema),
  });

  useEffect(() => {
    setValue('title', preloadedData[0]?.attributes?.title);
    setValue('description', preloadedData[0]?.attributes?.description);
  }, [preloadedData, setValue]);

  const onSubmit = async () => {
    const params = {
      title: watch('title'),
      description: watch('description'),
      type: TYPES.Policy,
      id: preloadedData[0]?.id,
    };
    if (!params.id) {
      dispatch(addTerms(params));
      dispatch(getTermsPolicy());
    } else {
      dispatch(updateTerms(params));
      toast.success('Privacy Policy update successfully.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: 'light',
      });
    }
    dispatch(getTermsPolicy());
  };

  const onRemoveTerms = async () => {
    await dispatch(removeTerms(preloadedData[0]?.id));
    toast.success('Term delete successfully.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      theme: 'light',
    });
    dispatch(getTermsPolicy());
  };

  return (
    <div>
      <div className={styles?.terms__top}>
        <Label black01 medium>
          Privacy Policy for App Users/Customer
        </Label>
      </div>
      <form className={styles?.formWrapper} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles?.formContainer}>
          <Label gray2 medium>
            Title
          </Label>
          <div className={styles?.input}>
            <Controller
              control={control}
              name='title'
              render={({ field }) => (
                <Input
                  type='text'
                  name='title'
                  {...field}
                  placeholder='Enter Title'
                  noBorder
                  bgGray
                  roundedLg
                  paddingLgX
                  black01
                />
              )}
            />
            {errors.title && (
              <Label sm primary>
                {errors.title.message}
              </Label>
            )}
          </div>
        </div>
        <div className={styles?.formContainer}>
          <Label gray2 medium>
            Description
          </Label>
          <div>
            <Controller
              control={control}
              name='description'
              render={({ field }) => (
                <textarea
                  name='description'
                  id='answer'
                  {...field}
                  cols='30'
                  placeholder='Enter Description'
                />
              )}
            />

            {errors.description && (
              <Label sm primary>
                {errors.description.message}
              </Label>
            )}
          </div>
        </div>
        {preloadedData.length > 0 ? (
          <div className={styles?.btnWrapper}>
            <Button approve height50 type='submit'>
              Update
            </Button>
            <Button decline height50 onClick={onRemoveTerms}>
              Delete
            </Button>
          </div>
        ) : (
          <div className={styles?.btnWrapper}>
            <Button approve height50 type='submit'>
              Save
            </Button>
          </div>
        )}
      </form>
      <ToastContainer />
    </div>
  );
};

export default PrivacyPolicy;
