import React from 'react';
import { Bar } from 'react-chartjs-2';

const ContentSubmissionGraph = ({ contentSubmissionData }) => {
  const contentSubmissionOption = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Content Submissions',
      },
    },
  };

  const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const contentSubmissionChart = {
    labels,
    datasets: [
      {
        label: 'Content Submissions',
        data: contentSubmissionData,
        backgroundColor: '#AB1634',
      },
    ],
  };

  return <Bar options={contentSubmissionOption} data={contentSubmissionChart} />;
};

export default ContentSubmissionGraph;
