/* eslint-disable no-unused-vars */
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// components
import Dashboard from './pages/Dashboard';
import ContentApproval from './pages/Content-Approval';
import ContentApprovalDetails from './pages/Content-Approval/pages/Details';
import ContentCreatorsList from './pages/Content-Creators-List';
import ContentCreatorDetails from './pages/Content-Creators-List/pages/Details';
import ApprovalsList from './pages/Approvals-List';
import ApprovalsDetails from './pages/Approvals-List/pages/Details';
import AppAnalysis from './pages/App-Analysis';
import Payout from './pages/Payout';
import PayoutDetails from './pages/Payout/pages/Details';
import Faq from './pages/Faq';
import FaqDetails from './pages/Faq/pages/viewFaq';
import NewFaq from './pages/Faq/pages/newFaq';
import Terms from './pages/Terms';
import NotFound from './pages/Not-Found';

import Login from './pages/Login';
import ForgotPassword from './pages/Forgot-Password';
import ResetPassword from './pages/Reset-Password';
import SignUp from './pages/SignUp';

import PublicRoute from 'components/GlobalUi/PublicRoute';
import PrivateRoute from 'components/GlobalUi/PrivateRoute';

// utils
import { SHORTFLIX_ROUTE } from './utils/constants';
import setAuthToken from 'services/setAuthToken';
import AccountService from './services/accountService';
import { selectToken } from './reducers/authReducer';

const AppRouter = () => {
  const accessToken = useSelector(selectToken);
  setAuthToken(accessToken);

  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path='/' element={<Navigate to={SHORTFLIX_ROUTE.Dashboard} />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path={SHORTFLIX_ROUTE.Dashboard} element={<Dashboard />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path={SHORTFLIX_ROUTE.ContentApproval} element={<ContentApproval />} />
          <Route
            path={SHORTFLIX_ROUTE.ContentApprovalDetails}
            element={<ContentApprovalDetails />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path={SHORTFLIX_ROUTE.ContentCreatorsList} element={<ContentCreatorsList />} />
          <Route path={SHORTFLIX_ROUTE.ContentCreatorDetails} element={<ContentCreatorDetails />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path={SHORTFLIX_ROUTE.ApprovalsList} element={<ApprovalsList />} />
          <Route path={SHORTFLIX_ROUTE.ApprovalsDetails} element={<ApprovalsDetails />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path={SHORTFLIX_ROUTE.AppAnalysis} element={<AppAnalysis />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path={SHORTFLIX_ROUTE.Payout} element={<Payout />} />
          <Route path={SHORTFLIX_ROUTE.PayoutDetails} element={<PayoutDetails />} />
        </Route>{' '}
        <Route element={<PrivateRoute />}>
          <Route path={SHORTFLIX_ROUTE.Faqs} element={<Faq />} />
          <Route path={SHORTFLIX_ROUTE.FaqsDetails} element={<FaqDetails />} />
          <Route path={SHORTFLIX_ROUTE.NewFaqs} element={<NewFaq />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path={SHORTFLIX_ROUTE.Terms} element={<Terms />} />
        </Route>
        <Route element={<PublicRoute noAuthOnly />}>
          <Route path={SHORTFLIX_ROUTE.Login} element={<Login />} />
        </Route>
        {/* hide for now */}
        {/* <Route element={<PublicRoute noAuthOnly />}>
          <Route path={SHORTFLIX_ROUTE.ForgotPassword} element={<ForgotPassword />} />
        </Route>
        <Route element={<PublicRoute noAuthOnly />}>
          <Route path={SHORTFLIX_ROUTE.ResetPassword} element={<ResetPassword />} />
        </Route>
        <Route element={<PublicRoute noAuthOnly />}>
          <Route path={SHORTFLIX_ROUTE.SignUp} element={<SignUp />} />
        </Route> */}
        <Route element={<PrivateRoute />}>
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
