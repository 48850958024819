import { createSelector, createSlice } from '@reduxjs/toolkit';
import { getFaqs, showFaq } from './thunks/faqsThunk';

const initialState = {
  isLoading: false,
  faqsData: [],
  faqDetails: [],
  error: '',
};

const faqsSlice = createSlice({
  initialState,
  name: 'faqs',
  reducers: {},
  extraReducers: {
    [getFaqs.pending]: (state) => {
      state.isLoading = true;
    },
    [getFaqs.fulfilled]: (state, action) => {
      state.faqsData = action.payload.data;
      state.isLoading = false;
    },
    [getFaqs.rejected]: (state) => {
      state.isLoading = false;
    },
    [showFaq.pending]: (state) => {
      state.isLoading = true;
    },
    [showFaq.fulfilled]: (state, action) => {
      state.faqDetails = action.payload.data;
      state.isLoading = false;
    },
    [showFaq.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { actions: faqsActions, reducer: faqsReducer } = faqsSlice;

const selectDomain = (state) => state.faqs || initialState;

export const selectFaqs = createSelector([selectDomain], (state) => state);

export { getFaqs, showFaq };
