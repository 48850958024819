import React from 'react';
import AccountService from 'services/accountService';
import { Navigate, Outlet } from 'react-router-dom';

// utils
import { SHORTFLIX_ROUTE } from 'utils/constants';

/*
  noAuth will tell us if a public route should be unaccessible when user is actively logged in. e.g login and register
  If so redirect them to the dashboard
*/
const PublicRoute = ({ noAuthOnly = false }) => {
  if (AccountService.checkAccessTokenValidity() && noAuthOnly) {
    return <Navigate to={SHORTFLIX_ROUTE.Dashboard} />;
  }
  return <Outlet />;
};

export default PublicRoute;
