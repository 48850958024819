import { createSelector, createSlice } from '@reduxjs/toolkit';
import { addTerms, showTerms, showPolicy, getTermsPolicy } from './thunks/termsThunk';

const initialState = {
  isLoading: false,
  termsPolicyData: [],
  termsData: [],
  termsDetails: [],
  policyDetails: [],
};

const termsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {},
  extraReducers: {
    [getTermsPolicy.pending]: (state) => {
      state.isLoading = true;
    },
    [getTermsPolicy.fulfilled]: (state, action) => {
      state.termsDetails = [...action.payload.data].filter(
        (item) => item.attributes?.type === 'terms',
      );
      state.policyDetails = [...action.payload.data].filter(
        (item) => item.attributes?.type === 'policy',
      );
      state.isLoading = false;
    },
    [getTermsPolicy.rejected]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
    },
    [addTerms.pending]: (state) => {
      state.isLoading = true;
    },
    [addTerms.fulfilled]: (state, action) => {
      state.termsData = action.payload.data;
      state.isLoading = false;
    },
    [addTerms.rejected]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
    },
    [showTerms.pending]: (state) => {
      state.isLoading = true;
    },
    [showTerms.fulfilled]: (state, action) => {
      state.termsDetails = action.payload.data;
      state.isLoading = false;
    },
    [showTerms.rejected]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
    },
    [showPolicy.pending]: (state) => {
      state.isLoading = true;
    },
    [showPolicy.fulfilled]: (state, action) => {
      state.policyDetails = action.payload.data;
      state.isLoading = false;
    },
    [showPolicy.rejected]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
    },
  },
});

export const { actions: termsActions, reducer: termsReducer } = termsSlice;

const selectDomain = (state) => state.terms || initialState;

export const selectTerms = createSelector([selectDomain], (termsState) => termsState);

export { getTermsPolicy, addTerms, showTerms, showPolicy };
