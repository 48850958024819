import React from 'react';
import { useNavigate } from 'react-router-dom';
// components
import Button from 'components/GlobalUi/Button/Button';
import { Label } from 'components/GlobalUi/Typography';

// styles
import styles from './index.module.scss';

// utils
import { SHORTFLIX_ROUTE } from 'utils/constants';

const Index = () => {
  const navigate = useNavigate();
  const goHome = () => {
    navigate(SHORTFLIX_ROUTE.Dashboard);
  };
  return (
    <div className={styles?.notFoundPageContainer}>
      <div className={styles?.wrapper}>
        <h1>404</h1>
        <div>
          <Button primary onClick={goHome}>
            <Label white>Go Home</Label>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Index;
