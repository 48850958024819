import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

// styles
import 'react-loading-skeleton/dist/skeleton.css';

const StyledDiv = styled.div`
  margin: 6px 0;
`;
const StyledSkeleton = ({ height, count, ...rest }) => {
  return <Skeleton height={height} wrapper={StyledDiv} count={count} borderRadius={10} {...rest} />;
};

export default StyledSkeleton;
