import React from 'react';
import { useTable, usePagination, useSortBy, useResizeColumns } from 'react-table';
import { Table } from 'react-bootstrap';

// components
import Button from '../Button/Button';

// styles
import styles from './table.module.scss';

// assets
import { ReactComponent as DoubleAngleLeft } from 'assets/svg/Double-angle-left.svg';
import { ReactComponent as AngleLeft } from 'assets/svg/Angle-left.svg';
import { ReactComponent as DoubleAngleRight } from 'assets/svg/Double-angle-right.svg';
import { ReactComponent as AngleRight } from 'assets/svg/Angle-right.svg';
import { ReactComponent as DualRing } from 'assets/svg/Dual-Ring-red.svg';
import { Label } from '../Typography';

const MainTable = ({ columns, data, isLoading }) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useSortBy,
    useResizeColumns,
    usePagination,
  );
  return (
    <div>
      <div className={styles?.tableContainer}>
        <Table {...getTableProps()}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, id) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()} key={id}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, id) => (
                      // Apply the header cell props
                      <th {...column.getHeaderProps()} key={id} style={{ padding: '20px 32px' }}>
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isLoading ? (
              <tr className={styles?.loadingTr}>
                <td>
                  <DualRing className={styles?.svgDualRing} />
                </td>
              </tr>
            ) : data.length ? (
              page.map((row, id) => {
                // Prepare the row for display
                prepareRow(row);
                const shouldHighlight = row.original.attributes?.read_at === '';
                return (
                  // Apply the row props
                  <tr
                    {...row.getRowProps()}
                    key={id}
                    className={`${shouldHighlight ? styles?.unRead : styles?.read}`}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, id) => {
                        // Apply the cell props
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={id}
                            style={{
                              height: '91px',
                              padding: '0px 32px',
                            }}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            ) : (
              <tr className={styles?.loadingTr}>
                <td>
                  <Label className={styles?.labelNoData}>No data</Label>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div className={styles?.pagination}>
        <div style={{ width: '100%' }}>
          Showing{' '}
          <strong>
            {1} - {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </div>
        <div className={styles?.paginationWrapper}>
          <Button
            grey01
            className={styles?.pageButton}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <DoubleAngleLeft />
          </Button>{' '}
          <Button
            grey01
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className={styles?.pageButton}
          >
            <AngleLeft />
          </Button>{' '}
          <Button
            grey01
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className={styles?.pageButton}
          >
            <AngleRight />
          </Button>{' '}
          <Button
            grey01
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className={styles?.pageButton}
          >
            <DoubleAngleRight />
          </Button>{' '}
        </div>
      </div>
    </div>
  );
};

export default MainTable;
