import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Input = styled.input.withConfig({
  shouldForwardProp: (prop) =>
    !['underline', 'noBorder', 'search', 'bgGray', 'roundedLg', 'paddingLgX', 'black01'].includes(
      prop,
    ),
})`
  width: 100%;
  height: 49px;
  outline: none;

  font-size: 16px;
  font-weight: 500;

  ${(props) =>
    props.noBorder &&
    css`
      border: none;
    `};

  ${(props) =>
    props.underline &&
    css`
      border-top: none;
      border-left: none;
      border-right: none;

      padding-bottom: 8px;
    `};

  ${(props) =>
    props.search &&
    css`
      background: #f2f5fa 0% 0% no-repeat padding-box;
      border-radius: 3px;
    `};

  ${(props) =>
    props.bgGray &&
    css`
      background: #eeeeee 0% 0% no-repeat padding-box;
    `};

  ${(props) =>
    props.roundedLg &&
    css`
      border-radius: 8px;
    `};
  ${(props) =>
    props.paddingLgX &&
    css`
      padding: 0 25px;
    `};

  ${(props) =>
    props.black01 &&
    css`
      color: #1a1a1a;
    `};
`;

Input.propTypes = {
  underline: PropTypes.bool,
  noBorder: PropTypes.bool,
  search: PropTypes.bool,
  bgGray: PropTypes.bool,
  roundedLg: PropTypes.bool,
  paddingLgX: PropTypes.bool,
  black01: PropTypes.bool,
};

export default Input;
