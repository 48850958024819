/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'utils/constants';

export const login = createAsyncThunk('auth/login', async (params, { rejectWithValue }) => {
  return await axios({
    method: 'post',
    url: API.AUTH_LOGIN,
    data: params,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});
