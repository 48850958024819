import Cookies from 'js-cookie';
import { COOKIE_EXPIRES_DAYS, COOKIE_PREFIX } from 'utils/constants';

const AccountService = {
  saveAuth: (token, attributes) => {
    Cookies.set(`${COOKIE_PREFIX}access_token`, token, {
      expires: COOKIE_EXPIRES_DAYS,
    });
    Cookies.set(`${COOKIE_PREFIX}user_info`, JSON.stringify(attributes), {
      expires: COOKIE_EXPIRES_DAYS,
    });
  },
  clearAuth: () => {
    Cookies.remove(`${COOKIE_PREFIX}user_info`);
    Cookies.remove(`${COOKIE_PREFIX}access_token`);
  },
  getAccessToken: () => [Cookies.get(`${COOKIE_PREFIX}access_token`)],
  checkAccessTokenValidity: () => {
    const [accessToken] = AccountService.getAccessToken();
    if (!accessToken) {
      return false;
    }
    /* const expiryDate = new Date();
    if (expiryDate < new Date()) {
      return false;
    } */
    return true;
  },
};

export default AccountService;
