/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// utils
import { API } from 'utils/constants';

export const getApprovalCreator = createAsyncThunk(
  'APPROVAL_CREATOR_LIST',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'GET',
      url: API.CREATORS_LIST,
      params: {
        page: params.page,
        per_page: params.perPage,
        filter_status: params.status,
        roles: ['Content Creator'],
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const approveCreator = createAsyncThunk(
  'CONTENT_APPROVE',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `${API.CREATOR_APPROVE}/${id}`,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const declineCreator = createAsyncThunk(
  'CONTENT_DECLINE',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `${API.CREATOR_DECLINE}/${params.id}`,
      data: { decline_reason: params.declineReason },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
export const getCreatorNotification = createAsyncThunk(
  'CREATOR_NOTIFICATION',
  async (_, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: API.CREATOR_NOTIFICATION,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
