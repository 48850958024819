import Cookies from 'js-cookie';

const storage = sessionStorage;

export const getItem = (key) => storage.getItem(key);
export const getParsedItem = (key) => JSON.parse(storage.getItem(key));
export const getParsed = (key) => JSON.parse(Cookies.get(key));
export const setItem = (key, value) => storage.setItem(key, value);
export const setAndStringify = (key, value) => storage.setItem(key, JSON.stringify(value));
export const setMany = (items) => items.map(({ key, value }) => storage.setItem(key, value));
export const removeItem = (key) => storage.removeItem(key);
export const clearAll = () => storage.clear();
