import React from 'react';
import { Image } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

// components
import { Label } from 'components/GlobalUi/Typography';
import Button from 'components/GlobalUi/Button/Button';

// assets
import approvedImage from 'assets/images/approve.png';
// styles
import styles from './modal.module.scss';

const StyledModal = ({
  show,
  title,
  onHide,
  approve,
  onApprove,
  onDecline,
  onChangeProofOfDisbursement,
  onChangeReason,
  loading,
  imageData,
  error,
}) => {
  return (
    <Modal show={show} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header className={styles?.header}>
        <Modal.Title id='contained-modal-title-vcenter' as='h5'>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles?.body}>
        {approve ? (
          <>
            <Image src={approvedImage} alt='' className={styles?.approveImage} />
            <div>
              <Label black01 md center>
                Approve Payout Request?
              </Label>
              <div className={styles?.flex}>
                <Form.Group controlId='formFile' className='mb-3'>
                  <Label sm primary>
                    {error}
                  </Label>
                  <Label htmlFor='file-upload' md black01 medium center>
                    Upload Proof of Disbursement
                  </Label>
                  <Form.Control
                    type='file'
                    accept='image/*'
                    onChange={onChangeProofOfDisbursement}
                    required
                  />
                </Form.Group>
                {/* eslint-disable-next-line no-nested-ternary */}
                {loading ? (
                  <div>Loading...</div>
                ) : imageData ? (
                  <img src={imageData} alt='Uploaded Image' className={styles?.uploadImage} />
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <>
            <Label black01 md center>
              <Label black01 md center medium>
                Enter Reason of Disapproval
              </Label>
            </Label>
            <Form>
              <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                <Form.Control
                  as='textarea'
                  rows={4}
                  placeholder='Enter Reason Here'
                  onChange={onChangeReason}
                  required
                />
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer bsPrefix={styles.footer}>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {approve ? (
          <Button className={styles?.approve} onClick={onApprove}>
            <Label sm white>
              APPROVE
            </Label>
          </Button>
        ) : (
          <Button className={styles?.decline} onClick={onDecline}>
            <Label sm white>
              SUBMIT
            </Label>
          </Button>
        )}

        <Button className={styles?.cancel} onClick={onHide}>
          <Label sm white>
            CANCEL
          </Label>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StyledModal;
