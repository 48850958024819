import { toast } from 'react-toastify';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { clearAll } from '../utils/storage';
import { login } from './thunks/authThunks';
import { setAndStringify, setItem, getParsedItem } from '../helpers/storage';
import AccountService from '../services/accountService';
import Cookies from 'js-cookie';
import { COOKIE_PREFIX } from '../utils/constants';

const initialState = {
  isLoading: false,
  token: [Cookies.get(`${COOKIE_PREFIX}access_token`)] ?? null,
  userInfo: getParsedItem('userinfo') ?? null,
  // permissions: getParsedItem('permissions') ?? [],
  isAuthenticated: getParsedItem('isAuthenticated') ?? false,
  error: [],
  showError: false,

  updateProfileLogs: {
    error: [],
    isFailed: false,
    isLoading: false,
    isSuccess: false,
  },
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      clearAll();
    },
    setIsAuthenticated: (state) => {
      state.isAuthenticated = true;
      setItem('isAuthenticated', true);
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.token = action.payload.token;
      state.userInfo = action.payload.data.attributes;
      // state.permissions = action.payload.permissions;

      // Set Data to sessionStorage
      setItem('token', action.payload.token);
      setAndStringify('userinfo', action.payload.data.attributes);
      AccountService.saveAuth(action.payload.token, action.payload.data.attributes);
      // setAndStringify('permissions', action.payload.permissions);
    },
    [login.pending]: (state) => {
      state.showError = false;
      state.isLoading = true;
    },
    [login.rejected]: (state, action) => {
      state.token = null;
      state.isLoading = false;
      state.error = action.payload;
      state.showError = true;
      toast.error('Login Failed');
    },
  },
});

export const { actions: authActions, reducer: authReducer } = slice;

export const { logout, setIsAuthenticated } = authActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.auth || initialState;

export const selectAuth = createSelector([selectDomain], (authState) => authState);
export const selectToken = createSelector([selectDomain], (authState) => authState.token);

/* --------------------------------------------------------------------------------- */

export { login };
