import styled from 'styled-components';

const Card = ({ children }) => {
  const StyledCard = styled.div.withConfig({})`
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
    width: 100%;

    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.01);
    }
  `;
  return <StyledCard>{children}</StyledCard>;
};

export default Card;
