import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  getCreatorsList,
  getCreatorDetails,
  getCreatorContents,
  getCreatorPayouts,
  removeCreator,
} from './thunks/contentCreatorThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  isLoading: false,
  isLoadingPayouts: false,
  creatorListData: [],

  activeCreatorListData: [],
  topCreatorData: [],
  creatorDetails: {},
  createDetailsIncluded: [],
  creatorVideoData: [],
  creatorContents: [],
  creatorPayouts: [],
  creatorRemoveData: [],
  logs: initialLog,
  submitLogs: initialLog,
  updateLogs: initialLog,
  deleteLogs: initialLog,
};

const slice = createSlice({
  name: 'contentCreator',
  initialState,
  reducers: {},
  extraReducers: {
    [getCreatorsList.pending]: (state) => {
      state.isLoading = true;
    },
    [getCreatorsList.fulfilled]: (state, action) => {
      state.creatorListData = action.payload;
      state.activeCreatorListData = action.payload.data
        .slice(0, 6)
        .filter((item) => item.attributes.status.toLowerCase().includes('active'));
      state.topCreatorData = [...action.payload.data]
        .filter((item) => item.attributes?.total_followers > 0)
        .sort((a, b) => {
          if (a.attributes?.total_followers === 0) {
            return 1;
          } else if (b.attributes?.total_followers === 0) {
            return -1;
          } else {
            return b.attributes?.total_followers - a.attributes?.total_followers;
          }
        })
        .slice(0, 5);
      state.isLoading = false;
    },
    [getCreatorsList.rejected]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
    },
    [getCreatorDetails.pending]: (state) => {
      state.isLoading = true;
    },
    [getCreatorDetails.fulfilled]: (state, action) => {
      state.creatorDetails = action.payload.data;
      state.createDetailsIncluded = action?.payload?.included;

      state.isLoading = false;
    },
    [getCreatorDetails.rejected]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
    },
    [getCreatorContents.pending]: (state) => {
      state.isLoading = true;
    },
    [getCreatorContents.fulfilled]: (state, action) => {
      state.creatorContents = action.payload.data.data;
      state.creatorVideoData = action.payload.data.included;
      state.isLoading = false;
    },
    [getCreatorContents.rejected]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
    },
    [getCreatorPayouts.pending]: (state) => {
      state.isLoadingPayouts = true;
    },
    [getCreatorPayouts.fulfilled]: (state, action) => {
      state.creatorPayouts = action.payload.data;
      state.isLoadingPayouts = false;
    },
    [getCreatorPayouts.rejected]: (state, action) => {
      console.log(action.payload);
      state.isLoadingPayouts = false;
    },
    [removeCreator.pending]: (state) => {
      state.isLoadingPayouts = true;
    },
    [removeCreator.fulfilled]: (state, action) => {
      state.creatorRemoveData = action.payload.message;
      state.isLoadingPayouts = false;
    },
    [removeCreator.rejected]: (state, action) => {
      console.log(action.payload);
      state.isLoadingPayouts = false;
    },
  },
});

export const { actions: contentCreatorActions, reducer: contentCreatorReducer } = slice;

const selectDomain = (state) => state.contentCreator || initialState;

export const selectContentCreator = createSelector([selectDomain], (state) => state);

export { getCreatorsList, getCreatorDetails, getCreatorContents, getCreatorPayouts, removeCreator };
