import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// utils
import { SHORTFLIX_ROUTE } from 'utils/constants';
import AccountService from 'services/accountService';

const PrivateRoute = () => {
  if (AccountService.checkAccessTokenValidity()) {
    return <Outlet />;
  }
  return <Navigate to={SHORTFLIX_ROUTE.Login} />;
};

export default PrivateRoute;
